/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins,wght@100;600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

:root {
    --primary-color: #ffffff;
    --primary-color1: #000000;
    --primary-color2: #FFC72C;
    --primary-color3: #F5F5F5;
    --primary-color6: #033D1E;
    --primary-color4: #1A4C33;
    --primary-color5: #DB2D6A;
    --primary-color12: #BABABA;
    --primary-color7: #FAFAFA;
    /* --primary-color6: #E2E2E2; */
    /* --primary-color7: #F2F2F2; */
    --primary-color8: #727272;
    --primary-color9: #BCBCBC;
    --primary-color10: #F8F8F8;
    --primary-color11: #819e8e;
    --primary-color13: #EBEBEB;
    --primary-color14: #F6F6F6;
    --primary-color15: #707070;
    --primary-color16: #EFF5F2;
    --primary-color17: #DBEBE3;
    --primary-color18: #dee2e6;
}

html {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-size: 19px;
    font-family: 'Open Sans', sans-serif !important;
    color: var(--primary-color6) !important;
    font-weight: 400;
    /* letter-spacing: 0.02em !important; */
    overflow: auto;
    background-color: var(--primary-color3) !important;
} 
p, th, td, span{
    font-size: 19px !important;
    font-family: 'Open Sans', sans-serif !important;  
    /* letter-spacing: 0.02em !important; */
    line-height: 30px !important;
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Open Sans', sans-serif !important;  
}
h4{
    font-size: 34px !important;
    color: #033D1E !important;
}
h5{
    font-size: 25px !important;
}
h2{
    font-family: 'Roboto', sans-serif !important;  
    font-weight: 700 !important;
    color: var(--primary-color4) !important;
    line-height: 70px !important;
}
button{
    text-transform: capitalize !important;
    font-family: 'Open Sans', sans-serif !important; 
    cursor: pointer !important; 
}

.homeBtn{
    background-color: var(--primary-color5) !important;
    font-size: 24px !important;
    padding: 5px 25px !important;
    color: var(--primary-color) !important;
    min-width: 240px !important;
}
.cmsfooterBtn{
  background-color: var(--primary-color5) !important;
  font-size: 24px !important;
  padding: 5px 25px !important;
  color: var(--primary-color) !important;
  min-width: 240px !important;
}
.headerBtn{
    background-color: var(--primary-color2) !important;
    font-size: 19px !important;
    padding: 7px 35px !important;
    min-width: auto !important;
    color: var(--primary-color4) !important;
    margin-left: 20px !important;
}

.homeBtn:hover{
  background-color: var(--primary-color2) !important;
  color: var(--primary-color4) !important;
  animation: pulse 1s infinite;
  transition: .1s;
}
.cmsfooterBtn:hover{
  background-color: var(--primary-color4) !important;
  color: var(--primary-color) !important;
  animation: pulse 1s infinite;
  transition: .1s;
}
.headerbtn:hover{
  background-color: var(--primary-color5) !important;
  animation: pulse 1s infinite;
  transition: .1s;
}
.headerBtn:hover{
  background-color: var(--primary-color5) !important;
  color: var(--primary-color) !important;
  animation: pulse 1s infinite;
  transition: .1s;
}

/* .css-hip9hq-MuiPaper-root-MuiAppBar-root{
    background-color: var(--primary-color) !important;
    box-shadow: none !important;
    backdrop-filter: blur(5px) !important;
}

.css-r6ewbb-MuiToolbar-root{
    padding: 15px 0px;
} */
.menuHeaderTop{
    background-color: var(--primary-color) !important;
    /* box-shadow: none !important; */
    backdrop-filter: blur(5px) !important;
    position: relative !important;
    position: fixed !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    transition: all 0.5s ease-in-out !important;
}
.menuHeaderTopAppBar{
    padding: 20px 0px 15px 0px;
}
.homeContainer{
  padding-top: 170px;
}

/* .manuItemBox > button:nth-child(3) {
    text-decoration: underline;
    font-size: 22px !important;
    font-weight: 600 !important;
  } */
.manuItemBox{
    justify-content: center;
    gap: 12px;
}
.manuItemBox button{
    color: var(--primary-color4);
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0px;
}
.manuItemBox .submenuOuter:nth-child(4) .MenuLink button{
    font-weight: bold !important;
    /* text-decoration: underline !important; */
    text-decoration-color: var(--primary-color4) !important;
    /* font-size: 22px !important; */
}
.logo_img{
    max-width: 50%;
}
.activeMenuItem {
    color: var(--primary-color5) !important;
    background-color: rgba(219,45,106,0.1) !important;
} 
.manuItemBox button:hover{
    background-color: rgba(26,76,51,0.1) !important;
}
.track_icon{
  margin-left: 15px;
}
/* .avatarBox, .css-1pqm26d-MuiAvatar-img{
    width: auto !important;
    height: auto !important;
    overflow: auto;
} */
.css-1lvtzne{
    justify-content: center;
}
.css-1lvtzne button{
    color: var(--primary-color4);
}
.footer{
    bottom: 0px;
    width: 100%;
    background-color: var(--primary-color4);
    box-shadow: 0px 26px 13.5px rgba(0, 0, 0, 0.0784314);
    color: var(--primary-color);
    padding-top: 50px;
}
.footer_title{
    color: var(--primary-color);
    font-family: 'Roboto', sans-serif !important;  
    font-weight: 700 !important;
    font-size: 22px !important;
}
.font-roboto{
    font-family: 'Roboto', sans-serif !important;  
}
.font-60-height{
    line-height: 70px !important;
}
.footer_subscription_title{
    font-size: 30px !important;
}
.footer_row {
    align-items: center;
    padding: 35px 20px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    filter: drop-shadow(0px 30px 30px rgba(5, 55, 84, 0.0509804));
    border-radius: 10px;
}
.footer_row1{
    display: flex;
    gap: 25px;
    width: 100%;
    padding: 20px 0px;
}
.footer_row1 .css-0{
    min-width: 16%;
}
.footer_description{
    line-height: 26px;
    max-width: 85%;
    color: var(--primary-color2);
    padding: 20px 0px;
}
.listBox{
    color: var(--primary-color);
    font-size: 16px;
    padding-top: 20px !important;
}
.listBox .css-10hburv-MuiTypography-root{
    font-family: 'Open Sans', sans-serif !important;
    line-height: 30px;
}
.listBox svg{
    color: var(--primary-color2);
}
.listBox .css-cveggr-MuiListItemIcon-root {
    min-width: 30px;
}
.listBox li{
    padding: 0px;
    padding-bottom: 5px;
}
.locationBox{
    align-items: self-start;
}
.locationBox .listBox .css-cveggr-MuiListItemIcon-root{
    padding-top: 7px;
}
.footer_logo{
    padding-bottom: 20px;
}
.footer_logo{
    max-width: 100%;
}
.footer_divider{
    height: 2px;
    width: 100%;
    background-color: var(--primary-color);
    margin: 0px;
}
.footer_block2{
    padding: 30px 0px;
}
.social_box svg{
    color: var(--primary-color2);
}
.social_box{
    display: flex;
    gap: 15px;
    align-items: center;
    padding-top: 20px;
}
.social_inner{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.social_inner img{
    padding: 0px; 
}
a{
    text-decoration: none !important;
}
a:hover{
    text-decoration: none;
    color: var(--primary-color2) !important;
}
input{
    font-weight: 400;
    background-color: var(--primary-color) !important;
}
.middle_block{
    max-width: 600px;
    margin: 0 auto;
    background: var(--primary-color10);
    box-shadow: 0px 3px 40px rgba(162, 162, 162, 0.101961);
    backdrop-filter: blur(5px);
    border-radius: 25px;
    padding: 50px 20px;
    margin-top: 70px;
    margin-bottom: 70px;
    
}
.middle_inner_block{
    width: fit-content;
    margin: 0 auto;
}
.submitBtn{
    background-color: var(--primary-color2) !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
    color: var(--primary-color4) !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    font-family: 'Open Sans', sans-serif !important;
    letter-spacing: 0.02em !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 20px !important;
    width: 100% !important;
}
.submitBtn:hover{
  background-color: var(--primary-color5) !important;
  color: var(--primary-color) !important;
  animation: pulse 1s infinite;
  transition: .1s;
}
.login_form label{
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: 'Open Sans', sans-serif !important;
}
.login_form label span{
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: 'Open Sans', sans-serif !important;
    letter-spacing: 0.02em !important;
    line-height: 30px !important;
}

.css-1q39md6-MuiButtonBase-root-MuiButton-root:hover{
    background-color: var(--primary-color2) !important;
}
.formTitle{
    font-family: 'Roboto', sans-serif !important;  
    font-weight: 600 !important;
    font-size: 35px !important;
    color: var(--primary-color4);
}
.formTitle2{
    color: var(--primary-color2) !important;
}
.footer_column{
    display: flex;
    justify-content: center;
}
.footer_row .css-1kabx79-MuiInputBase-root-MuiInput-root:before{
    border: none !important;
}
.footer_row input{
    font-size: 16px;
    font-weight: 400 ;
    font-family: 'Open Sans', sans-serif;
    color: var(--primary-color8);
    letter-spacing: 0.02em;
}
.submitBtn2{
    background-color: var(--primary-color4) !important;
    color: var(--primary-color) !important;
    margin-top: 0px !important;
}
.order_box{
    max-width: 700px;
}
.order_box .middle_inner_block{
    max-width: 535px;
}
.tracking_form .MuiFormControl-root{
    width: 100%;
    margin-bottom: 30px;
}
.tracking_form label{
    font-weight: bold !important;
    font-size: 16px !important;
    color: var(--primary-color4);
}
.tracking_form label.Mui-focused{
    color: inherit;
}
.tracking_form input{
    margin-top: 5px;
    border-bottom: none;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 12px 15px;
}
.tracking_form input:focus{
    border: 1px solid var(--primary-color2);
}
.tracking_form .css-v4u5dn-MuiInputBase-root-MuiInput-root:before{
    border-bottom: none !important;
    border-color: transparent;
    outline: 0;
}
.tracking_form .css-v4u5dn-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before{
    border-bottom: none !important;
    border-color: transparent;
    outline: 0;
}
.tracking_form .css-v4u5dn-MuiInputBase-root-MuiInput-root:after{
    border: none;
}
.tracking_form input::placeholder{
    color: var(--primary-color8) !important;
    font-size: 14px !important;
}
.errorMessage, .loginErrorMessage{
    display: none;
    color: red;
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    padding-bottom: 10px;
}
.errorMessage3{
  color: red;
  font-weight: 600;
  font-size: 13px;
  text-align: left;
  padding-bottom: 10px;
}

.errorMessage2{
  display: none;
  color: red;
  font-weight: 600;
  font-size: 13px;
  text-align: left;
  padding-bottom: 10px;
}
.product_img{
    max-width: 90%;
    object-fit: cover;
}

/* manage order page design */
.priceText{
    font-size: 18px !important;
    font-family: 'Roboto', sans-serif !important;  
    color: var(--primary-color4);
}
.priceTextDel{
    font-size: 20px;
    color: var(--primary-color11);
}
.productFormBox .MuiSelect-select{
    padding: 10px;
    text-align: left;
}
.orderContainer label{
    font-weight: bold;
}
.spanLabel{
    font-weight: normal;
    color: var(--primary-color4);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
.quantityBox button{
    background-color: transparent;
    color: var(--primary-color4);
    /* min-width: 40px;
    max-width: 40px; */
    font-size: 22px;
    padding: 0px 5px;
    border-radius: 0px;
    min-width: 45px;
    width: 45px;
}
.quantityBox input{
    width: 60px;
    border: none;
    text-align: center;
}
.quantityBox{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px solid #EBEBEB;
    border-radius: 5px;
}
.quantityBox .css-q0jhri-MuiInputBase-root-MuiInput-root:before{
    border: none !important;
}
.quantityBox .css-q0jhri-MuiInputBase-root-MuiInput-root.Mui-focused:before{
    border: none;
}
.cartBtn{
    max-width: 300px !important;
}
.ProductImageBox{
    max-width: 100%;
    /* margin: 0 auto; */
    text-align: center;
    /* border: 1px solid #EBEBEB; */
    border-radius: 10px;
    /* padding: 10% 0px; */
}
.detailImgOuterBox{
  /* height: auto !important;
  max-height: 400px !important; */
 
}

/* checkout css */
.MuiStepper-root.MuiStepper-horizontal.css-m5vj9m-MuiStepper-root{
    max-width: 25%;
    margin: 0 auto;
}
.MuiStep-root.MuiStep-horizontal.css-1bw0nnu-MuiStep-root{
    display: flex;
    gap: 10px;
}
.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-disabled.css-j5w0w9-MuiStepConnector-root{
    display: none;
}
.nextIcon{
    font-size: 14px !important;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    color: var(--primary-color8);
}
.MuiStep-root:last-child p{
    display: none;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root{
    width: 35px !important;
    height: 35px !important;
    color: var(--primary-color9) !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active, .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
    color: var(--primary-color4) !important;
}
.css-1hv8oq8-MuiStepLabel-label.Mui-active, .css-1hv8oq8-MuiStepLabel-label.Mui-completed{
    font-weight: bold !important;
}

.errorMsg{
    color: red !important;
    margin-top: -15px !important;
    padding-bottom: 15px !important;
    max-width: 400px !important;
    min-width: 400px !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
}
.cartContainer{
    background-color: var(--primary-color4);
    padding-top: 100px;
    text-align: center;
}
.cartBox{
    background-color: var(--primary-color3);
    box-shadow: inset 0px 4px 3px -2px rgba(0,0,0,.06);
    padding: 30px 0px;
}
.tableQuantityBox{
    /* width: 30px; */
    width: 150px;
}

.cartTable th{
    font-weight: bold;
    color: var(--primary-color1);
    background-color: var(--primary-color7);
    font-size: 14px !important;
    padding: 15px 5px !important;
    border: 0px !important;
    /* text-transform: capitalize !important; */
}
.cartTable th:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.cartTable th:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.cartTable td{
    font-weight: 400;
    color: var(--primary-color1);
    font-size: 15px !important;
    padding: 25px 5px !important;
    /* text-transform: capitalize !important; */
}
.cartTable .css-11xur9t-MuiPaper-root-MuiTableContainer-root{
    box-shadow: none !important;
    background-color: transparent !important;
}
.cartTable .css-i4bv87-MuiSvgIcon-root{
    color: var(--primary-color4);
}
.col-right-padding{
    padding-left: 20px !important;
}
.col-left-padding{
    padding-right: 20px !important;
}
.checkoutBtn{
   line-height: 1.25 !important; 
}
.stepperGrid{
    padding: 30px 0px;
    margin: 0 auto;
}
.stepperList{
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    padding: 10px;
    border-radius: 50%;
    text-align: center;
    border: 1px solid var(--primary-color12);
    color: var(--primary-color12);
    display: flex;
    justify-content: center;
    align-items: center;
}
.stepperList.active{
    background-color: var(--primary-color2);
    color: var(--primary-color1);
    border: 1px solid var(--primary-color2);
}
.stepperGrid svg{
    fill: var(--primary-color9);
}
.editBtn{
    background-color: var(--primary-color2) !important;
    color: var(--primary-color1) !important;
    max-width: 150px !important;
}
.b1.Mui-disabled{
    opacity: 0.5;
    cursor: not-allowed !important;
}
.stepperList.active svg{
    fill: var(--primary-color);
}
.block1 input:focus-visible{
    border: none !important;
    outline: none;
}
.loginLink{
    color: var(--primary-color4);
}

/* form css */
.css-1wuilmg-MuiAutocomplete-root{
    width: 100%;
}
.checkoutForm label{
    font-size: 14px !important;
    font-family: 'Open Sans', sans-serif !important;
    color: var(--primary-color1) !important;
}
.checkoutForm input{
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px !important;
    color: var(--primary-color8) !important;
    padding: 11px 15px;
    border-radius: 5px;
}
.checkoutForm select{
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px !important;
    color: var(--primary-color8) !important;
    padding: 11px 15px;
    border-radius: 5px;
}
.checkLabel{
    padding-left: 10px;
    font-weight: bold;
}
.BillingBox{
    display: block;
}
.ShippingBox, .BillingBox, .SenderBox{
    padding-top: 30px;
}
.check-input{
    border-radius: 50% !important;
    cursor: pointer;
}
.formColumn{
    padding:0px 20px !important;
}
.cartBtn2{
    width: 100%;
    max-width: 100% !important;
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid var(--primary-color3); /* Light grey */
    border-top: 10px solid var(--primary-color4); /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .orderDetailBox{
    padding: 100px 0px !important;
  }
  .orderDetailBox h2{
    color: var(--primary-color4) !important;
  }
  .cartIemCount{
    width: 18px;
    height: 18px;
    font-size: 12px !important;
    background-color: var(--primary-color4);
    border-radius: 50%;
    color: var(--primary-color);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .orderBtn1{
    padding: 10px 15px !important;
    max-width: 300px !important;
    background-color: var(--primary-color2) !important;
    color: var(--primary-color1) !important;
    border-radius: 10px !important;
    font-weight: bold !important;
    margin-right: 15px !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
  }
  .orderBtn2{
    background-color: var(--primary-color4) !important;
    color: var(--primary-color) !important;
  }
  .orderDetailBox svg{
    /* font-size: 50px; */
    fill: var(--primary-color4);
  }
  .middle_order_box h4, .middle_order_box h5{
    color: var(--primary-color4);
    font-weight: bold;
  }
  .rowHeader{
    background-color: var(--primary-color4);
    color: var(--primary-color);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 10px;
  }
  .rowBody{
    background-color: var(--primary-color);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 10px;
  }
  .detailRow{
    padding: 5px 0px;
  }
  .fontLabel{
    font-size: 14px !important;
  }
  .fontBold{
    font-weight: bold !important;
    color: var(--primary-color4);
    font-size: 18px !important;
  }
  .block-column{
    padding: 10px;
    text-align: center;
    border: 1px solid var(--primary-color4);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .block-column h5{
    color: var(--primary-color4);
    font-weight: 600;
  }
  .columnImage{
    max-width: 100%;
  }
  .dashboardContainer{
    width: calc(100% - 260px) !important;
    position: absolute;
    right: 0px;
    /* margin-top: 100px; */
    /* padding: 200px 0px; */
  }
  .dashboardContainer .row{
    /* gap: 30px; */
    /* flex-wrap: nowrap; */
  }
  .dashboardContainer .css-hyum1k-MuiToolbar-root{
    min-height: 80px;
    background-color: var(--primary-color);
  }
  #formCountrySelect{
    min-width: 200px;
  }
  .radioLabel{
    display: flex;
    gap: 10px;
  }
  .carousel .thumb{
    display: none !important;
  }
  .carousel .control-dots{
    left: 20%;
    left: 9% !important;
    text-align: left !important;
  }
  .carousel .control-dots .dot{
    width: 18px !important;
    height: 18px !important;
    background-color: var(--primary-color5) !important;
  }
  .carousel.carousel-slider .control-arrow{
    display: none !important;
  }
  .carousel .thumbs-wrapper{
    display: none;
  }
  .carousel-Box .carousel.carousel-slider{
    /* min-height: 500px; */
  }
  .border-bottom{
    width: 100%;
    height: 2px;
    margin: 10px 0px 5px 0px;
    color: var(--primary-color2);
    background-color: var(--primary-color2);
    opacity: 1;
  }
  .video-img::after{
    content: url("../../public/assets/img/video_img.png");
    position: absolute;
    right: -105px;
    top: -40px;
  }


  /* product carousel css */
  .logo_carousel .react-multi-carousel-track{
    margin: 0% 0%;
  }
  .country_carousel .react-multi-carousel-track{
    margin: 0% 0%;
  }
  .multi_carousel .react-multiple-carousel__arrow--left{
    left: 0%;
    background-color: transparent;
    border-radius: none;
  }
  .multi_carousel .react-multiple-carousel__arrow--left::before{
    content: "\f0d9";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 70px;
    color: var(--primary-color5);
  }
  .multi_carousel .react-multiple-carousel__arrow--right::before{
    content: "\f0da";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 70px;
    color: var(--primary-color5);
  }
  .multi_carousel .react-multiple-carousel__arrow:hover, .multi_carousel .react-multiple-carousel__arrow:hover{
    background-color: transparent;
  }
  .multi_carousel .react-multiple-carousel__arrow--right{
    right: 0%;
    background-color: transparent;
    border-radius: none;
  }
  .country_img{
    width: 200px;
    height: 100px;
    transition: all 0.3s ease;
  }
  .country_img:hover{
    transform: scale(1.2);
    /* cursor: pointer; */
  }
  .block4_box .carousel .control-dots{
    left: 0% !important;
    text-align: center !important;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .block4_box .carousel .control-dots .dot{
    background-color: var(--primary-color2) !important;
  }
  .Question_image{
    padding-bottom: 30px;
    padding-left: 10px;
  }
  /* .block4_title::after{
    content: url('../../public/assets/img/work_icon.png');
    padding-left: 8px;
  } */
  .colTitle{
    color: var(--primary-color9) !important;
    text-transform: uppercase;
    font-size: 16px !important;
    font-weight: 100;
    font-family: 'Roboto', sans-serif !important;  
  }
  .colSubTitle{
    color: var(--primary-color4) !important;
    font-size: 19px !important;
    font-family: 'Roboto', sans-serif !important; 
    padding-bottom: 10px; 
    font-weight: 700 !important;
  }
  .headerLine{
    width: 50px;
    height: 2px;
    background-color: var(--primary-color2);
    color: var(--primary-color2);
    margin: 10px 0px;
    opacity: 1;
  }
  .carousel-Box .carousel .slide img{
    max-width: 85%;
    width: auto;
  }
  .carousel-Box .carousel .slide{
    text-align: right;
  }
  .middleHeader{
    padding: 0px 10px;
    font-weight: 700 !important;
}
/* Default styles for carousel items */
.logoImgBox {
  /* Your default item styles here */
  padding: 20px;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;
}

.slide_image {
  transition: transform 0.3s ease-in-out;
}

/* Apply grayscale to all images in .logoImgBox by default */
.logoImgBox .slide_image {
  filter: grayscale(100%);
}

/* Hover effect for .logoImgBox */
.logoImgBox:hover {
  background-image: url("../../public/assets/img/hover_bg.svg");
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-top: 70px;
  padding-bottom: 70px;
}

/* Remove grayscale and transform on hover */
.logoImgBox:hover .slide_image {
  transform: translateY(-30%);
  filter: grayscale(0%);
}

/* Apply the default-hover class styles */
.logoImgBox.default-hover {
  background-image: url("../../public/assets/img/hover_bg.svg");
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-top: 70px;
  padding-bottom: 70px;
}

/* Remove grayscale and transform for .slide_image when .default-hover is applied */
.logoImgBox.default-hover .slide_image {
  transform: translateY(-30%);
  filter: grayscale(0%);
}
/* Apply styles to the image when the third slider is hovered */
/* .logoImgBox.default-hover:hover .slide_image {
  transform: translateY(-30%);
  filter: grayscale(0%);
} */

/* Remove hover styles when any other slider is hovered */
/* .logoImgBox:not(.default-hover):hover {
  background-image: none;
  cursor: default;
  padding-top: 0;
  padding-bottom: 0;
} */

/* Optionally, reset styles for the image in other sliders on hover */
/* .logoImgBox:not(.default-hover):hover .slide_image {
  transform: translateY(0);
}  */

.footerInput{
    border-radius: 0px !important;
    padding: 10px 15px !important;
    width: 60% !important;
    font-size: 16px !important;
    font-family: 'Roboto', sans-serif !important;  
    height: 100% !important;
}
.subscribeBtn{
    background-color: var(--primary-color2) !important;
    color: var(--primary-color4) !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    padding: 0px 20px !important;
    height: 100% !important;
    font-family: 'Roboto', sans-serif !important;  
}
.webHeader_logo{
    max-width: 100%;
}
.css-l6jsyc:hover{
    background-color: #fff !important;
}
/* .login_form input{
    
} */
.productContainer{
    background-color: var(--primary-color);
    border-radius: 10px;
    padding: 5%;
    /* margin-bottom:60px; */
    margin-top: 40px;
}
.cartMainContainer{
    margin-top: 40px;
}
.cartColLeft, .cartColRight{
    background-color: var(--primary-color);
    border-radius: 10px;
    padding: 5% 2% !important;
}
.cartColRight{
    padding: 0% !important;
}
.paymentCol{
    padding: 2% !important;
}
.spinner-container{
    position: relative;
}
.paymentOption{
    padding: 5px 15px;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    margin: 5px 0px;
}
.paymentOption.active{
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    margin: 5px 0px;
}
.checkoutCartInfoBox{
    background-color: var(--primary-color);
    border-radius: 10px;
    /* padding: 3% 2%  !important; */
}
.orderBoxContainer{
    padding: 50px 0px;
}
.flex{
    display: flex !important;
}
.flex-top{
    align-items: top !important;
}
.flex-end{
    justify-content: end !important;
}
.flex-center{
    justify-content: center !important;
}
.flex-start{
    justify-content: start !important;
}
.flex-align-center{
  align-items: center !important;
}
.line-22{
  line-height: 22px !important;
}
.line-50{
  line-height: 50px !important;
}
.line-40{
  line-height: 40px !important;
}
.font-11{
    font-size: 11px !important;
}
.font-12{
    font-size: 13px !important;
}
.font-13{
    font-size: 13px !important;
}
.font-14{
    font-size: 14px !important;
}
.font-15{
    font-size: 15px !important;
}
.font-16{
    font-size: 16px !important;
}
.font-18{
    font-size: 18px !important;
}
.font-24{
    font-size: 24px !important;
}
.font-25{
    font-size: 25px !important;
}
.font-20{
    font-size: 20px !important;
}
.font-22{
    font-size: 22px !important;
}
.font-30{
    font-size: 30px !important;
}
.font-38{
  font-size: 38px !important;
}
.font-26{
  font-size: 26px !important;
}
.font-45{
  font-size: 45px !important;
}
.font-60{
    font-size: 60px !important;
}
.text-underline{
    text-decoration: underline !important;
}
.cursor-pointer{
    cursor: pointer !important;
}

.color{
    color: var(--primary-color) !important;
}
.color1{
    color: var(--primary-color1) !important;
}
.color2{
    color: var(--primary-color4) !important;
}
.color3{
    color: var(--primary-color8) !important;
}
.color4{
    color: var(--primary-color5) !important;
}

.color5{
  color: var(--primary-color2) !important;
}

.font-bold{
    font-weight: 700 !important;
}
.font-medium{
    font-weight: 400 !important;
}
.font-600{
    font-weight: 600 !important;
}
.font-capital{
    text-transform: uppercase !important;
}
.b1{
    font-size: 14px !important;
    color: var(--primary-color) !important;
    border-radius: 6px !important;
    background-color: var(--primary-color4) !important;
    padding: 8px 30px !important;
}
.b1:hover{
  animation: pulse 1s infinite;
  transition: .1s;
  background-color: var(--primary-color5) !important;
  color: var(--primary-color) !important;
}
.b3{
    background-color: var(--primary-color2) !important;
    color: var(--primary-color6) !important;
}
.b4:hover{
  animation: pulse 1s infinite;
  transition: .1s;
  background-color: var(--primary-color5) !important;
  color: var(--primary-color) !important;
}
.b4{
    background-color: var(--primary-color5) !important;
    color: var(--primary-color) !important;
}
.b4:hover{
  animation: pulse 1s infinite;
  transition: .1s;
  background-color: var(--primary-color4) !important;
  color: var(--primary-color) !important;
}
.b5{
  background-color: var(--primary-color6) !important;
  color: var(--primary-color) !important;
}
.b4:hover{
  animation: pulse 1s infinite;
  transition: .1s;
  background-color: var(--primary-color2) !important;
  color: var(--primary-color1) !important;
}
.cartTotalTable{
    padding: 0px !important;
    /* min-height: 550px !important; */
}
.cartTotalTable td{
    padding: 12px 5px !important;
}
.totalTableBody{
    min-height: 200px;
}
.paymentBox .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
    color:var(--primary-color1) !important;
    font-size: 14px !important;
    font-weight: bold !important;
}
.paymentBox .StripeElement{
    padding: 15px 0px 20px 0px;
}
.buyCartTotal{
  min-height: 470px !important;
}
/* .buyCartTotalRight{
  max-height: 470px !important;
} */
.cartGridColumnRight{
  height: 470px !important;
}
.paymentMainBox{
  padding: 0px !important;
  background-color: transparent !important;
}
/* .paymentBox .StripeElement iframe label{
    font-weight: bold !important;
} */
.b2{
    min-width: 270px !important;
    margin-bottom: 10px !important;
    cursor: pointer;
}
.detailBox{
    padding: 5% 10% !important;
    margin-bottom: 50px !important;
}
.cartGridColumn{
    position: relative;
}
.css-rderiq-MuiButtonBase-root-MuiButton-root:hover{
    background-color: unset !important;
}
.css-b4mw19-MuiButtonBase-root-MuiButton-root:hover{
    background-color: unset !important;
}
.css-rderiq-MuiButtonBase-root-MuiButton-root:focus{
    transition: none !important;
}
.flexBox{
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}
.flexBoxDash{
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.flexBoxDash p{
  padding-top: 0px;
}
.flexBox2{
  display: flex;
  gap: 20px;
}
.flexBoxSignup{
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.flexBoxSignupInner{
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.send-detail-table{
    margin-top: 30px;
}
.send-detail-table th,.send-detail-table td{
    border: 1px solid #EBEBEB;
    text-align: center;
}
.send-detail-table th{
    background-color: #FAFAFA !important;
}
.react-datepicker-wrapper{
    position: relative !important;
    display: block !important;
}
.react-datepicker-wrapper svg{
    position: absolute !important;
    right: 15px !important;
    top: 50% !important;
    transform: translate(0%, -50%) !important;
    width: 35px !important;
    height: 35px !important;
    /* display: none; */
}
.react-datepicker-wrapper input{
    width: 100% !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    padding: 11px 15px !important;
    
}
.orderHistory .row{
    flex-wrap: wrap;
}
.check-input{
    accent-color: var(--primary-color2);
}
.gridInnerBlockRight{
    margin-left: 10px;
    border-radius: 10px;
    padding: 25px;
    background-color: var(--primary-color);
}
.gridInnerBlockLeft{
    margin-right: 10px;
    padding: 25px;
    border-radius: 10px;
    background-color: var(--primary-color);
    min-height: 500px;
}
.invoiceName{
    max-width: 200px;
    overflow-wrap: break-word;
}
.islanderOrderBox input{
    width: 90%;
    border: 1px solid var(--primary-color13);
    font-size: 13px !important;
    padding: 5px 10px !important;
}
.islanderOrderBox td{
    max-width: 200px;
    border-bottom: none;
    border-right: 1px solid var(--primary-color13);
    padding: 10px 15px;
    font-size: 16px !important;
    font-weight: 600;
    color: var(--primary-color1);
}   
.islanderOrderBox td:last-child{
    border-right: none;
}
.islanderOrderBox tr{
    outline: 1px solid var(--primary-color13) !important;
    border-radius: 8px !important;
    margin: 10px 0px;
}
.orderTable{
    margin: 20px 0px 40px 0px;
}
.addRow:disabled, .orderBtn:disabled{
    cursor: none;
    opacity: 0.5;
}
.addRow{
    padding: 5px 15px !important;
    font-weight: 600 !important;
}
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
    background-color: var(--primary-color6) !important;
    opacity: 87% !important;
}
.productDescription{
    line-height: 20px !important;
}
.read-more-link{
    cursor: pointer;
}
.priceTotalBox{
    padding: 10px 30px;
    border-top: 2px solid var(--primary-color14) !important;
    margin-bottom: 30px;
}
.summeryTitle{
    border-bottom: 2px solid var(--primary-color14) !important;
    padding: 0px 0px 20px 0px;
}
.shipmentAddress{
    padding-top: 10px;
    max-width: 230px;
    line-height: 30px;
}


/* home css */
.gridRow {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
}
.videoBox{
    width: 65% !important;
    border-radius: 20px;
    margin: 0 auto;
   
}
.videoBox2{
    width: 65% !important;
    border-radius: 20px;
}
.country_box{
    display: grid;
    gap: 20px;
    row-gap: 50px;
    grid-template-columns: repeat(3, 1fr);
}
.comingSoon_img{
    max-width: 100%;
}
.middle_coming_block{
    max-width: 500px;
    background: unset;
    box-shadow: none;
    margin: 0 auto;
}
.country_carousel{
  max-width: 60%;
  margin: 0 auto;
}
.provider_img{
  transition: transform .7s ease-in-out;
  transform: rotate(0deg);
}
.provider_img:hover{
  /* animation: shake 0.5s;
  animation-iteration-count: infinite; */
  animation: rotateAnimation 1.5s forwards;
}

.pop_img{
  transition: transform .7s ease-in-out;
  transform: scale(1);
  width: 100%;
}
.pop_img:hover{
  /* animation: shake 0.5s;
  animation-iteration-count: infinite; */
  animation: popupAnimation 1.5s forwards;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg); /* Initial rotation angle */
  }
  100% {
    transform: rotate(360deg); /* Final rotation angle */
  }
}

@keyframes popupAnimation {
  0% {
    transform: scale(1); /* Initial rotation angle */
  }
  100% {
    transform: scale(1.06); /* Final rotation angle */
  }
}


.videoOverlay{
  position: absolute;
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* z-index: 9999; */
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 65% !important;
  border-radius: 20px;
  margin: 0 auto;
}
.videoOverlay svg{
  font-size: 100px;
  color: var(--primary-color);
  cursor: pointer;
}
.videoContainer{
  position: relative;
}
/* @keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
} */

/* sidebar css */
.drawer {
    /* max-width: 260px; */
  }
  .drawerPaper {
    /* width: 260px; */
    border: none !important;
  }
  .nested {
    padding-left: 16px; /* Adjust the padding according to your requirement */
  }
  .iconBox {
    min-width: 40px !important;
    overflow: hidden;
  }
  .iconBox2 {
    min-width: 30px !important;
    overflow: hidden;
  }
  .menulist {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .menuBox span {
    font-size: 16px !important;
    color: #727272 !important;
    font-weight: 700 !important;
  }
  .adminMenuBox .linkBox{
    border-top: 1px solid #727272;
    /* border-Bottom: 1px solid #727272; */
  }
  .userMenuBox .linkBox:nth-child(1),.userMenuBox .linkBox:nth-child(2),.userMenuBox .linkBox:nth-child(3){
    /* border-top: 1px solid #727272; */
    border-Bottom: 1px solid #727272;
  }
  .adminMenuBox .linkBox:first-child{
    border-top: 0px solid #727272;
    /* border-Bottom: 1px solid #727272; */
  }
  .adminMenuBox .linkBox:nth-child(5){
    /* border-top: 0px solid #727272; */
    border-Bottom: 1px solid #727272;
  }
  /* .menuBox > div:nth-child(1) {
    border-bottom: 1px solid #727272;
  } */
  /* .menuBox > div:nth-child(4),
  .menuBox > div:nth-child(6) {
    border-top: 1px solid #727272;
  } */
  .manuAdminBox > div:nth-child(8){
    border-top: 1px solid #727272;
  }
  .manuAdminBox > div:nth-child(10),.manuAdminBox > div:nth-child(12),.manuAdminBox > div:nth-child(13),.manuAdminBox > div:nth-child(14){
    border-top: 1px solid #727272;
  }
  .nestedMenuBox {
    text-align: center !important;
    margin-left: 0px !important;  
    /* margin-right: 10px !important; */
    padding-bottom: 10px !important;;
  }
  .nestedMenuBox span {
    font-size: 14px !important;
  }
  .nestedMenuBox div {
    padding: 0px;
    padding: 5px 0px;
    justify-content: center;
  }
  .nestedMenuBoxExpand {
    /* margin-left: 45px !important; */
    padding-bottom: 10px !important;
  }
  .nestedMenuBoxExpand span {
    font-size: 14px !important;
  }
  .nestedMenuBoxExpand div{
    padding: 0px;
    padding: 0px 0px 0px 8px;
  }
  .nestedMenuBoxExpand div:first-child{
    padding-left: 20px;
    /* overflow: hidden; */
    /* max-height: 40px; */
  }
 

  /* dashboardStyles.css */

.dashboardInner {
    margin-top: 80px;
    padding: 35px;
  }
  
  .dashboardInnerHeader h5 {
    font-size: 25px !important;
    color: #000 !important;
    font-weight: 700;
  }
  
  .dashboardInnerHeader p {
    font-size: 14px !important;
    color: #727272 !important;
    font-weight: 700;
    cursor: pointer;
  }
  
  .dashboardComponent {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    cursor: pointer !important;
    min-height: 170px;
  }
  
  .gridHeader {
    font-size: 20px !important;
    color: #000 !important;
    font-weight: 700 !important;
    padding-left: 20px;
  }
  
  .gridContent {
    padding-top: 10px;
    font-size: 16px !important;
    color: #727272 !important;
    line-height: 25px !important;
  }
  
  .faqContent {
    font-size: 16px !important;
    color: #727272 !important;
    line-height: 25px !important;
  }
  .faqContent p{
    font-size: 16px !important;
  }
  
  .gridBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding-top: 20px;
  }
  
  .gridBoxInner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding-top: 20px;
  }
  
  .dashboardFaq {
    background-color: var(--primary-color);
    border-radius: 10px;
    margin-top: 20px;
   
  }
  .faqBox{
    max-height: 500px;
    overflow-y: auto;
  }
  
  .gridBoxCol {
    margin-top: 20px;
  }
  
  .faqHeader {
    font-size: 20px !important;
    color: #000 !important;
    font-weight: 700 !important;
  }
  
  .hrLine {
    height: 2px;
    background-color: #f6f6f6;
    margin: 0px;
  }
  
  .faq {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  /* dashboard header css */
  .sideberOpenIcon{
    display: none;
  }
  .closeSidebarIcon{
    display: none !important;
  }
  .coming_soon_loginBox{
    padding-top: 200px;
  }
  .emailLink{
    color: var(--primary-color4) !important;
  }
  .emailLink:hover{
    color: var(--primary-color4) !important;
  }

  /* buy pages design */
  .gridFlexBox{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* home page css */--primary-color4
  .headerFlexBox{
    display: flex;
    justify-content: center
  }
  .flex-grid{
    justify-content: space-around;
    align-items: center;
    display: flex;
  }
  .purchase_left_box{
    background-color: var(--primary-color4);
    border-radius: 10px;
    padding-bottom: 20px;
  }
  .shopBgBox{
    background-image: url('../../public/assets/gif/shop.gif');
    background-size: cover;
    height: 500px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }
  .bgBox{
    height: 200px;
    width: 100%;
  }
  .shopImg{
    max-width: 100%;
  }
  .country_heading{
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(0%, -50%);
  }

  /* dashboard header css */
  /* .headerBox {
    width: calc(100% - 234px);
    position: sticky;
    right: 0;
    z-index: 9;
  } */
  
  /* Styles for header */
  .header {
    background-color: var(--primary-color) !important;
  }
  header{
    box-shadow: none !important;
    z-index: 9999 !important;

  }
  /* Styles for dashHeaderBtn */
  .dashHeaderBtn {
    background-color: #033D1E !important;
    color: #fff !important;
    font-size: 14px !important;
    padding: 0px 25px !important;
    height: 40px;
    border-radius: 5px !important;
    margin-left: 10px !important;
  }
  
  /* Styles for userText */
  .userText {
    color: #000;
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  
  /* Styles for userPosition */
  .userPosition {
    color: #727272;
    font-size: 12px !important;
  }
  .HeaderShadow{
    box-shadow: none !important;
  }
  .dashboardContainer header{
    box-shadow: none !important;
  }
  .avatarBox img{
    width: auto !important;
    height: auto !important;
    overflow: auto;
  }
  .avatarBox {
    margin-left: 5px !important;
  }
  .support_icon{
    cursor: pointer;
  }
  .main{
    display: flex;
  }
  /* .drawer.minimized {
    width: 80px;
  } */

  /* sidebar css */
  .containerExpand{
    width: calc(100% - 234px);
    position: absolute;
    right: 0px;
  }
  .containerExpand2{
    width: calc(100% - 260px);
    position: absolute;
    right: 0px;
  }
  /* .containerExpand3{
    width: calc(100% - 234px);
    position: absolute;
    right: 0px;
  } */
  .dashContainer{
    width: calc(100% - 80px);
    position: absolute;
    right: 0px;
  }
  .headerBoxExpand{
    width: calc(100% - 234px);
    position: fixed;
    right: 0px;
    z-index: 999;
  }
  .headerBox{
    width: calc(100% - 80px);
    position: fixed;
    right: 0px;
  }

  /* Animation Five */


.animated-letter{
	font-size: 60px !important;
    font-weight: bold !important;
    font-family: 'Roboto', sans-serif !important;  
    animation: fadeIn 0.3s ease-in-out;
    opacity: 0;
    display: inline-block;
    animation-fill-mode: forwards ;
    color: var(--primary-color) !important;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  
  
  .animated-letter:nth-child(1) { animation-delay: 0s; }
.animated-letter:nth-child(2) { animation-delay: 0.3s; }
.animated-letter:nth-child(3) { animation-delay: 0.6s; }
.animated-letter:nth-child(4) { animation-delay: 0.9s; }
.animated-letter:nth-child(5) { animation-delay: 1.2s; }
.animated-letter:nth-child(6) { animation-delay: 1.5s; }
.animated-letter:nth-child(7) { animation-delay: 1.8s; }
.animated-letter:nth-child(8) { animation-delay: 2.1s; }
.animated-letter:nth-child(9) { animation-delay: 2.4s; }
.animated-letter:nth-child(10) { animation-delay: 2.7s; }
.animated-letter:nth-child(11) { animation-delay: 3s; }
.animated-letter:nth-child(12) { animation-delay: 3.3s; }
.animated-letter:nth-child(13) { animation-delay: 3.6s; }
.animated-letter:nth-child(14) { animation-delay: 3.9s; }
.animated-letter:nth-child(15) { animation-delay: 4.2s; }
.animated-letter:nth-child(16) { animation-delay: 4.5s; }
.animated-letter:nth-child(17) { animation-delay: 4.8s; }
.animated-letter:nth-child(18) { animation-delay: 5.1s; }
.animated-letter:nth-child(19) { animation-delay: 5.4s; }
.animated-letter:nth-child(20) { animation-delay: 5.7s; }
.animated-letter:nth-child(21) { animation-delay: 6s; }
.animated-letter:nth-child(22) { animation-delay: 6.3s; }
.animated-letter:nth-child(23) { animation-delay: 6.6s; }
.animated-letter:nth-child(24) { animation-delay: 6.9s; }
.animated-letter:nth-child(25) { animation-delay: 7.2s; }
.animated-letter:nth-child(26) { animation-delay: 7.5s; }
.animated-letter:nth-child(27) { animation-delay: 7.8s; }
.animated-letter:nth-child(28) { animation-delay: 8.1s; }
.animated-letter:nth-child(29) { animation-delay: 8.4s; }
.animated-letter:nth-child(30) { animation-delay: 8.7s; }


.animation-container {
  display: inline-block;
  /* font-size: 24px;
  font-family: Arial, sans-serif;
  white-space: nowrap; */
}

.react-datepicker__input-container{
    width: 100% !important;
    display: block !important;
}
.react-datepicker-wrapper {
    position: relative !important;
    display: block !important;
    width: 100%;
}


@keyframes swiftUp {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .swift-up-animation {
    animation: swiftUp 1s ease-in-out;
  }


  .left-to-right-animation {
    /* CSS animation to move the content from left to right */
    opacity: 0;
    transform: translateX(-100%);
    animation: slideInFromLeft 1.5s ease-in-out forwards;
  }
  
  @keyframes slideInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

/* date field css */
.slotRow{
  background-color: var(--primary-color7);
  padding: 20px 10px;
  border-radius: 4px;
  margin: 0px;
}
.slotRow .react-datepicker__day{
  color: var(--primary-color4);
  font-weight: bold !important;
}
.slotRow .react-datepicker__day--disabled{
  color: var(--primary-color12);
  font-weight: 400 !important;
}
.slotRow .react-datepicker__day--selected{
  background-color: var(--primary-color2);
  color: var(--primary-color4);
}
.slotRow .react-datepicker__day--selected:hover{
  color: var(--primary-color4);
  background-color: var(--primary-color2);
}
.slotRow .react-datepicker__day--keyboard-selected{
  background-color: transparent !important;
  color: var(--primary-color4) !important;
  border: none;
  outline: none;
}
.slotRow .react-datepicker__day--keyboard-selected:hover{
  background-color: transparent !important;
  background-color: #f0f0f0 !important;
}

/* accordian design css */
/* .imgLogoBox{
  width: 55px;
  height: 55px;
}
.imgLogoBox img{
  max-width: 100%;
} */
.accordianExpandIcon{
  position: absolute;
  right: 20px;
}
.AccordionBar{
  background-color: var(--primary-color) !important;
  box-shadow: 30px 20px 60px 40px rgba(0, 0, 0, 0.05);
}
.accordianHeader .MuiAccordionSummary-content.Mui-expanded{
  margin-bottom: 0px !important;
}
.accordianPanel svg{
  color: var(--primary-color4);
}
.accordianPanel .css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  background-color: var(--primary-color) !important;
  box-shadow: unset;
  box-shadow: 30px 20px 60px 40px rgba(0, 0, 0, 0.05);
}
  

/* header.js css */
.submenuOuter{
  position: relative;
}
.submenu{
  position: absolute;
  width: 100%;
  padding: 10px 5px;
  background-color: var(--primary-color);
  color: var(--primary-color6);
  min-width: 150px;
}
.submenu button{
  width: 100%;
  justify-content: start;
}
.cartInnerSection{
  width: 350px;
  /* max-width: 350px; */
  min-height: 50px;
  max-height: 400px;
  overflow-y: auto;
  padding: 0px 20px;
}
.cartPopup{
  width: 350px;
  position: absolute;
  top: 40px;
  left: -20px;
  padding: 20px 0px;
  background-color: var(--primary-color);
  border-radius: 5px;
  box-shadow: 30px 20px 60px 40px rgba(0, 0, 0, 0.05);
  z-index: 99999;
  display: none;
}
.cartPopup.open{
  display: block;
}
.cartPopup th{
  font-size: 15px !important;
  color: var(--primary-color4) !important;
  font-weight: bold;
  padding: 0px 10px;
}
.cartPopup td{
  font-size: 15px !important;
  color: var(--primary-color1) !important;
  font-weight: 600;
  padding: 5px 10px;
}
.cartPopup .quantityMain{
  margin: 5px 0px;
}
.cartImg{
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.cartFlexBox{
  display: flex;
  align-items: center;
}
.cartFlexBox svg{
  color: var(--primary-color4);
}
.cartRow{
  border-bottom: 1px solid var(--primary-color12);
  padding: 10px 0px;
}
.cartRow:last-child{
  border-bottom: none;
}
.quantityMain{
  color: var(--primary-color1) !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary-color13) !important;
  border-radius: 5px;
  margin: 10px 0px;
}
.quantityInput {
  padding: 0px 5px;
  color: var(--primary-color1) !important;
  font-size: 16px !important;
  width: 35px;
  border: none;
  text-align: center;
}

.actionIcon {
  font-size: 16px !important;
  color: var(--primary-color1) !important;
}
.quantityInput {
  padding: 0px 5px;
  color: var(--primary-color1) !important;
  font-size: 16px !important;
  width: 35px;
  border: none;
  text-align: center;
}

.productAdd,
.productRemove {
  background-color: var(--primary-color13) !important;
  border-radius: 0px !important;
  min-width: 26px !important;
  height: 32px !important;
}

.productAdd {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.productRemove {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
/* .cartFlexCol{
  justify-content: start;
} */
.paymentLogoBox{
  position: absolute;
  right: 15px;
  top: 2px;
  /* height: 80px; */
  /* transform: translate(0%, -50%); */
  width: 100px;
  /* height: 50px; */
}
.paymentLogoBox2{
  top: 25%;
}
.paymentLogo{
  max-width: 100%;
}
.radioParentBox{
  position: relative !important;
}
.paymentOption{
  position: relative;
}
.purchase_barrel{
  max-width: 100%;
}
.homePurchaseBlock{
  /* width: 40%;
  padding: 20px 0px 10px 0px; */
  /* height: 200px; */
}
.paymentAddressBox{
  border: 1px solid var(--primary-color13);
  border-radius: 10px;
  padding: 10px 15px;
  margin-top: 10px;
  max-width: 250px;
}
.paymentAddressBox p{
    /* line-break: anywhere; */
 word-break: break-word;
}
.adminInner {
  margin-top: 80px;
  padding: 35px;
}

/* button {
  transition: .1s;
} */
/* button:hover {
  animation: pulse 1s infinite;
  transition: .1s;
} */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(.9);
  }
    100% {
    transform: scale(1);
  }
}
.sectionBlock{
  height: 70px;
}
.headerAvatarIcon{
  cursor: pointer;
}
.cartPopup{
  cursor: auto;
}
.cartFlexCol img, .product_img_box img{
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
.productOuterBox{
  width: 100%;
  /* max-width: 100%;*/
  /* height: 300px;   */
  position: relative;
  overflow: hidden; /* Hide any overflow when the image scales on hover */
  transition: transform 0.3s;

}


.productOuterBox::before {
  content: "";
  display: block;
  padding-bottom: 80%; /* This sets the aspect ratio (e.g., 1:1 for a square) */
}
.productListImg{
  /* object-fit: cover;
  max-width: 100%;
  /* position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  /* transform: translate(-50%, -50%); 
  transition: transform .5s; */

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain; 
  transition: transform 0.5s;
}
.productOuterBox:hover .productListImg{
    transform: scale(1.1);
}
.addressFlexBox{
  display: flex;
  flex-wrap: wrap;
}
.error{
  color: red !important;
}
.supportFormBox{
  min-width: 350px;
  padding: 5px 0px 15px 0px;
}
.supportFormBox label{
  color: var(--primary-color4) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
  margin-top: 10px !important;
}
.supportFormBox div:first-child label{
  margin-top: 0px !important;
}
.supportFormBox input, .supportFormBox textarea{
  margin-top: 3px;
  font-size: 14px !important;
}
.supportFormBox textarea{
  resize: none;
}
.supportFormBox button{
  margin-top: 20px;
}
.totalHeader{
  border-bottom: 1px solid #E2E2E2;
}


/* styles.css */
.step-menu {
  list-style: none;
  padding: 0;
  display: flex;
  margin-bottom: 0px;
  margin-top: 15px;
  margin-bottom: -20px;
  margin-left: 10px;
}
.step-menu span{
  font-size: 12px !important;
  line-height: 20px !important;
  z-index: 99;
  font-weight: 600 !important;
  color: var(--primary-color1) !important;
}
 .step {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px 6px 15px;
  font-size: 12px;
  position: relative;
  background-color: var(--primary-color16);
  margin-right: 20px;
}
/* .step::after{
  content: "";
  position: absolute;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid transparent;
  border-left: 15px solid ;
  right: -12px;
  transition: all 0.3s ease-in;
} */
/* .step::before{
  content: "";
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  border-left: 10px solid black;
  right: -20px;
  transition: all 0.3s ease-in;
} */
.step::before, .step::after {
  background: var(--primary-color17);
  border-radius: 2px;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  transition: all 1s;
  height: 16px;
}
.step::before {
  /* box-shadow: -1px 1px 0 #1897c0, -2px 2px 0 #1897c0, -3px 3px 0 #1897c0, -4px 4px 0 #1897c0; */
  transform: skew(45deg);
  top: 0;
}
.step::after {
  /* box-shadow: 1px 1px 0 #1897c0, 2px 2px 0 #1897c0, 3px 3px 0 #1897c0, 4px 4px 0 #1897c0; */
  transform: skew(-45deg);
  bottom: 0;
}
/* .filled {
  background-color: var(--primary-color17); 
  color: var(--primary-color1);
} */
  
.step-fill{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 35%; /* Adjust this value to control the fill percentage */
  /* background-color: var(--primary-color2); */
  z-index: 9; /* Change this color to the desired fill color */
}
.step-fill::before{
  background: var(--primary-color2);
  border-radius: 2px;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  transition: all 1s;
  height: 16px;
  transform: skew(45deg);
  top: 0;
}
.step-fill::after{
  background: var(--primary-color2);
  border-radius: 2px;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  transition: all 1s;
  height: 16px;
  transform: skew(-45deg);
  bottom: 0;
}
/* .step-fill::after{
  background: var(--primary-color17);
  border-radius: 2px;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  transition: all 1s;
  height: 16px;
  transform: skew(45deg);
  top: 0;
} */
.cartBtnBox{
  position: relative;
  margin-bottom: -10px;
}
.adsCartMsg{
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  font-size: 14px !important;
  color: var(--primary-color5);
  font-weight: 600 !important;
}
.cartAlertMsg{
  font-size: 14px !important;
  color: var(--primary-color5);
  font-weight: 600 !important;
}
.emailError{
  color: red !important;
  padding-top: 5px;
  line-height: 22px !important;
}
.cardFlexBox{
  flex-wrap: wrap;
 gap: 5px;
}
.cartTotalBox{
  min-height: 300px !important
}
.pl{
  padding-left: 10px;
}
.pr-15{
  padding-right: 15px !important;
}
.pl-15{
  padding-left: 15px !important;
}
.pt-15{
  padding-top: 15px !important;
}
.pr-10{
  padding-right: 10px !important;
}
.pl-10{
  padding-left: 10px !important;
}
.pb-10{
  padding-bottom: 10px !important;
}
.p-20{
  padding: 10px;
}
.p-15{
  padding: 15px !important;
}
.contact-btn{
  width: 97%;
  border-radius: 10px;
  padding: 20px !important;
  margin-bottom: 20px !important;
}
.boxFlex{
  position: relative;
  display: flex;
}
.boxFlex img{
  position: absolute;
  right: 10px;
}
.pt{
  padding-top: 10px;
}
.faq_block{
  background: unset;
  box-shadow: none;
  min-width: 75%;
}
.faqInnerBlock{
  width: 75%;
}
.faq_block ol > li::marker {
  font-weight: bold;
}
.faq_block svg{
  color: var(--primary-color4);
}
.contact_box{
  max-width: 100%;
  margin-top: -20px;
}
.cartContactLeft{
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 40px 30px;
}

/* estimator page css */
.estimatorBox{
  padding: 40px 0px;
  max-width: 80%;
}
.flexRight{
  display: flex;
  align-items: center;
  justify-content: end;
}
.estimatorBoxLeft{
  border: 1px solid var(--primary-color13);
  border-radius: 10px;
  /* padding: 30px; */
}
.estimatorRightInnerBox{
  justify-content: end;
}
.estimatorQuantityBox button{
  min-width: 55px;
  max-width: 55px;
  width: auto;
}
.EInnerBox{
  width: 80%;
}
.sizeRow{
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
.sizeRow:last-child{
  margin-bottom: 0px;
}
.contact_img{
  max-width: 100%;
}




.CMSfooter{
  width: 100%;
  background-color:var(--primary-color2);
  box-shadow: 0px 26px 13.5px rgba(0, 0, 0, 0.0784314);
  color: var(--primary-color);
  padding: 30px 0;
  margin-bottom: 50px;
  margin-top: 50px;
}

.mb100 { margin-bottom: 100px;}
.popOverText span{
  font-size: 12px !important;
  margin: 0px;
  line-height: 10px !important;
}
.popOverText.selected{
  color: var(--primary-color2);
}


/* admin css changes */
.admin-modal__container select, .admin-modal__container input[type="file"]{
  font-size: 14px;
}
.admin-modal__container input[type="file"]{
  border: var(--bs-border-width) solid var(--bs-border-color);
  padding: 6.5px 15px;
  border-radius: 5px;
  line-height: 1.42857
}


/* .adminMenuBox> div:nth-child(5){
  border-top: 1px solid #727272;
} */
.adminMenuBox > div:nth-child(6) {
  border:none;
}
/* .adminMenuBox > div:nth-child(7), .adminMenuBox > div:nth-child(9), .adminMenuBox > div:nth-child(11), .adminMenuBox > div:nth-child(12), .adminMenuBox > div:nth-child(13){
  border-top: 1px solid #727272;
} */
/* .adminMenuBox > div:nth-child(4){
  border: none;
} */
/* .adminMenuBox > div:nth-child(1){
  border: none;
} */
.adminMenuBox span {
  font-size: 15px !important;
}
.adminMenuBox .nestedMenuBoxExpand{
  margin-left: 0px;
}

.adminMenuBox .nestedMenuBoxExpand span{
  max-width: 170px !important;
}
.service_block{
  width: 100%;
  height: 300px;
}
.service_block img{
  max-width: 100%;
  height: 100%;
}
.service_inner{
  max-width: 80%;
}
/* order history page and invoice css */
.orderId{
  cursor: pointer;
}
.invoiceBox p{
  font-size: 15px !important;
  font-weight: 600;
  line-height: 25px !important;
}
.historyTable th{
  font-size: 15px !important;
  text-align: center;

}

/* .historyTable tr{
  background-color: var(--primary-color) !important;
} */
.historyTable td{
  font-size: 15px !important;
  padding: 3px 10px !important;
  height: 28px !important;
  background-color: var(--primary-color) !important;
}

.adminMenuBox svg{
  font-size: 25px;
}
.checkbox-color svg{
  color: var(--primary-color2);
}
.makeStyle_background{
  padding: 15px;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border-radius: 10px;
  background-color: #fff;
}
.box-modal__container{
  width: 600px;
  max-width: 600px !important;
  height: 360px !important;
}
.box-modal__container_tracking{
  width: 600px;
  max-width: 600px !important;
  height: 500px !important;
}
.editText{
  width: 100px;
}
.editText2{
  width: 60px;
}
.w-80{
  width: 80%;
}
.w-90{
  width: 90%;
}
.w-60{
  width: 60%;
}
.bg-white{
  background-color: var(--primary-color);
  border-radius: 10px;
}
.sendBoxPadding{
  padding-top: 20px;
}


/* profile page css */
.tabs-block button{
  font-size: 18px;
  text-transform: uppercase !important;
  color: var(--primary-color6);
  margin: 15px 0px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.tabBox .tabs-block button:last-child{
  margin: 0px;
  padding: 30px 0px;
}
.tabBox .tabs-block button:first-child{
  margin: 0px;
  padding: 30px 0px;
}
.tabBox{
  padding: 0px 35px;
}
.tabs-block{
  background-color: var(--primary-color);
  min-width: 18%;
}
.tabs-block svg{
  font-size: 40px;
}
.tabBox button.Mui-selected{
  color: var(--primary-color2);
  font-weight: bold;
  
}
.tabBox .MuiTabs-indicator{
  background-color: var(--primary-color2);
}
.tabPanelInner{
  width: 100%;
}
.tab-form{
  max-width: 40%;
}
.tab-form1{
  max-width: 70%;
}
.photolLabel{
  display: inline-block;
  font-size: 14px !important;
  color: var(--primary-color);
  border-radius: 6px;
  background-color: var(--primary-color5);
  padding: 10px 30px;
  cursor: pointer;
  margin-top: 1rem;
}
.photolLabel:hover{
  animation: pulse 1s infinite;
  transition: .1s;
  background-color: var(--primary-color2) !important;
  color: var(--primary-color1) !important;
}
.optimizeBox{
  position: fixed;
  right: 35px;
  top: 85px;
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 5px 15px;
  text-align: center;
  cursor: pointer;
}
.optimizeBox.disabled{
  user-select: none;
  opacity: 0.5;
  cursor: none;
  background-color: var(--primary-color9);
}
.productImgCell{
  max-width: 70px;
  /* min-width: 40px; */
  /* height: 60px; */
}
.productImgCell img{
  max-width: 100%;
}
.sliderContentBox{
  font-size: 21px !important;
    letter-spacing: 0.5px;
    line-height: 40px !important;
}
.imgAltText input{
  height: 20px !important;
}
.imgOrderDropdown{
  padding: 16.5px 14px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  min-width: 70px;
}
/* .tab-main{
  padding-top: 200px;
} */

/* help center css */
.dottedBorder{
  border-bottom: 2px dotted var(--primary-color2);
}
.selected {
  font-weight: bold;
}
.helpBlockGrid{

}

span.faq-highlight {
  font-size: 15px !important;
  font-weight: bold;
  color: var(--primary-color5);
}
span.faq-highlight:hover{
  color: var(--primary-color5);
}
/* loader js */
/* Loader overlay */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:  var(--primary-color4); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the loader is on top of everything */
}

/* Loader animation */
.loader {
  border: 6px solid #f3f3f3; /* Light gray border */
  border-top: 6px solid var(--primary-color5); /* Blue border for animation */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite; /* Animation for spinning */
}
.mapLoader{
  width: 40px;
  height: 40px;
}
.mapLoader-container{
  margin-top: 60px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* .container-content-box{
    padding: 30px 30px !important;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    border-radius: 10px;
    background-color: #fff;
} */
.highlight-text{
  color: var(--primary-color2);
}
.home-icon-img{
  width: 40px;
  height: 40px;
}
.calgary-btn{
  background-color: rgba(219,45,106,0.1) !important;
  color: var(--primary-color5) !important;
  padding: 5px;
}
.ESt-cost{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.est-cost-btn{
  background-color: rgba(219,45,106,0.1) !important;
  color: var(--primary-color5) !important;
  padding: 12px 20px;
}
.country-select-btn select.form-select {
  background-color: rgba(26,76,51,0.1) !important;
  color: var(--primary-color4);
  font-weight: 700;
  width: 200px;
}
.country-select-btn{
  background-color: rgba(26,76,51,0.1) !important;
  color: var(--primary-color4);
}
.estimatorBox-header{
    padding: 20px;
    background: var(--primary-color10);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.quantityBox input {
  width: 60px;
  border: none;
  text-align: center;
  height: 38.5px;
  background: var(--primary-color10) !important;
}
.homePurchaseBlock {
  background-image: url("../../public/assets/img/empty-banner.svg");
  /* background-size: cover; */
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 640px;
}
.purchaseBlock-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 250px;
  margin-right: 150px;
}
.purchaseBlock-left-content {
  width: 380px;
  float: left;
}
.purchaseBlock-right-content {
  width: 380px;
  float: left;
}
.purchaseBlock-right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 250px;
}
.purchaseBlock-left-content2 {
  width: 380px;
  float: left;
  text-align: right;
}
.purchaseBlock-right-content2 {
  width: 380px;
  float: left;
  text-align: right;
}
.purchaseBlock-left-content-img {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.purchaseBlock-left-content-img2 {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.homePurchaseBlock .colTitle{
  font-size: 18px !important;
}
.homeBlock-Title{
  font-size: 16px !important;
}
.menuSelected{
  color: var(--primary-color5);
}

/* color code css */
.orderReceived{
  background-color: green !important;
  padding: 5px 15px;
  color: var(--primary-color) !important;
  border-radius: 5px;
  min-width: 150px;
  width: 130px !important;
  display: inline-block;
}
.pickedUp{
  background-color: orange !important;
  padding: 5px 15px;
  border-radius: 5px;
  min-width: 150px;
  width: 130px !important;
  display: inline-block;
}
.readyToShip{
  background-color: red !important;
  padding: 5px 15px;
  color: var(--primary-color) !important;
  border-radius: 5px;
  min-width: 150px;
  width: 130px !important;
  display: inline-block;
}
.itemReceiving{
  background-color: var(--primary-color2) !important;
  padding: 5px 15px;
  border-radius: 5px;
  min-width: 150px;
  width: 130px !important;
  display: inline-block;
}
.packing{
  background-color: var(--primary-color2) !important;
  padding: 5px 15px;
  border-radius: 5px;
  min-width: 150px;
  width: 130px !important;
  display: inline-block;
}
.fillingSpace{
  background-color: var(--primary-color2) !important;
  padding: 5px 15px;
  border-radius: 5px;
  min-width: 150px;
  width: 130px !important;
  display: inline-block;
}

span.Click-me {
  cursor: pointer;
  color: var(--primary-color5) !important;
}
.about-section-bottom{
  margin-top: 50px !important;
}
.empty-barrel-icon.MuiBox-root.css-0 {
  background: #fff;
  border-radius: 24px;
  padding: 20px;
  margin-bottom: 20px;
}
.icon-header {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}
.colTitle-1{
font-size: 20px !important;
font-style: normal;
font-weight: 700 !important;
line-height: normal !important;
}
.empty-barrel-text{
  font-size: 16px !important;
font-style: normal !important;
font-weight: 600;
line-height: 20px!important;
}
.icon-header img{
  width: 41px;
  height: 40px;
}

.buy-empty-btn{
  border-radius: 16px;
  padding: 10px 25px !important;
}
.headerFlexBox {
  display: flex;
  justify-content: center;
}
.buy-empty-btn:hover{
  background-color: var(--primary-color4) !important;
  color: var(--primary-color) !important;
}
img.home-empty-img {
  max-width: 100%;
}
.video-container{
  position: relative;
}

.fixsocial {
  display: inline-block;
  position: absolute;
  left: -245px;
  top: 50%;
  z-index: 9;
  color: var(--primary-color);
  width: 270px;
  margin: 0px 0px;
  background: var(--primary-color4);
  padding: 12px;
  -moz-border-radius-topright: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -webkit-border-bottom-right-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-box-shadow: 0px 40px 100px 0px rgba(80, 80, 80, 0.1);
  -moz-box-shadow: 0px 40px 100px 0px rgba(80, 80, 80, 0.1);
  box-shadow: 0px 40px 100px 0px rgba(80, 80, 80, 0.1);
  webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.fixsocial .arrow {
  display: inline-block;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -11px;
  margin: auto;
  background: var(--primary-color2);
  border-radius: 50%;
  border: 1px solid var(--primary-color);
}
.fixsocial .arrow #fixsocial {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 4px 13px 0px 13px; */
  transform: rotate(0deg);
  webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 25px;
}
.fixsocial .arrow #fixsocial .fa-angle-right:before {
  color: var(--primary-color);
  font-size: 14px;
}
.errorMessage, .loginErrorMessage2{
  color: red;
  font-weight: 600 !important;
  font-size: 13px !important;
  text-align: left;
  padding-bottom: 10px;
  max-width: 400px;
  text-align: center;
  text-transform: capitalize;
}
.non-clickable-menu-with-submenu {
  pointer-events: none;
}
.non-clickable-menu-with-submenu:hover{
  background-color: var(--primary-color2) !important;
}
.email-id-box{
  background-color: #E2E2E2 !important;
}
.printPdf{
  position: absolute !important;
  right: 20px;
  top: 20px
}
.trackDetail-table{
  max-height: 300px;
  overflow-y: auto;
}
.trackingTable{
  /* position: relative; */
  /* border: 1px solid gray; */
}
.about-image-1{
  max-width: 100%;
  margin-top: 20px;
}
.service-main-box{
 margin-bottom: 20px !important;
}
.email-text-contact{
  word-break: break-word;
}
.insurance-subtitle {
  font-size: 16px !important;
  margin-bottom: 10px !important;
}

.questionPlaceholder::placeholder{
  color: var(--primary-color1) !important;
}

.footer-copyright{
  max-width: 100% !important;
  text-align: center;
}
.cartTable .css-1ex1afd-MuiTableCell-root{
  border-bottom: 0px !important;
}
.cartTable .css-179a09d-MuiTableCell-root{
  border-bottom: 0px !important;
}
.cartTable .css-34nofg-MuiTableRow-root {
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}
.insurance-section-select{
  width: 50% !important;
}
.username-text{
  display: flex;
  align-items: center;
}
.headerBox {
    z-index: 999;
}

.loader2-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}
.loader2-container3 {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.loader2-container4 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

/* Loader */
.loader2 {
  border: 8px solid #f3f3f3;
  border-top: 8px solid var(--primary-color4);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin2 2s linear infinite;
}
.paypalLoader{
  border: 8px solid var(--primary-color);
  border-top: 8px solid var(--primary-color4);
  margin: 0 auto;
  margin-bottom: 19px;
}

/* Animation */
@keyframes spin2 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.adminInnerPage__tabs .css-jpln7h-MuiTabs-scroller{
  overflow: auto !important;
}

.notPointerEvent{
  pointer-events: none!important ;
}
.logo_mob_img{
  max-width: 50px !important;
}
.CookieConsent{
  z-index: 9999 !important;
  background-color: var(--primary-color2) !important;
  color: var(--primary-color4) !important;
  font-weight: bold !important;
}
.CookieConsent button{
  font-size: 14px !important;
  color: var(--primary-color) !important;
  border-radius: 6px !important;
  background-color: var(--primary-color4) !important;
  padding: 8px 30px !important;
}
.CookieConsent button:hover{
  animation: pulse 1s infinite;
  transition: .1s;
  background-color: var(--primary-color5) !important;
  color: var(--primary-color) !important;
}
.paypalInnerBox{
  min-height: 200px;
}

.priorityProductOuterBox{
  width: 100%;
  /* max-width: 100%;*/
  /* height: 300px;   */
  position: relative;
  overflow: hidden; /* Hide any overflow when the image scales on hover */
  transition: transform 0.3s;

}


.priorityProductOuterBox::before {
  content: "";
  display: block;
  padding-bottom: 80%; /* This sets the aspect ratio (e.g., 1:1 for a square) */
}
.priorityProductListImg{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain; 
  transition: transform 0.5s;
}
.priorityProductOuterBox:hover .priorityProductListImg{
    transform: scale(1.1);
}
.community-image-1{
  max-width: 50%;
  margin: 2
  0px 0px;
}

.product_cart_img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
}
.aspect-ratio {
  width: 100%;
  padding-bottom: 80%; /* 5:4 aspect ratio (4/5 = 0.8) */
  position: relative;
}

/* .product_cart_img {
  max-width: 100%; 
  height: auto;
  display: block; 
} */

.aspect-ratio-container {
  width: 100%;
  padding-bottom: 80%; /* 5:4 aspect ratio (4/5 = 0.8) */
  position: relative;
  overflow: hidden; /* Hide any content that exceeds the container's dimensions */
}

.cartQuantityBox{
  padding: 10% 0%;
}

.ProductImageBox .css-bhp9pd-MuiPaper-root-MuiCard-root{
  box-shadow: none !important;
}

.product-Description-box {
  padding: 15px;
}
.emptyProductBox{
  padding: 20px 40px;
  border: 1px solid var(--primary-color4);
  background-color: var(--primary-color);
  max-width: 300px;
  text-align: center;
  border-radius: 10px;
}
.sendImgOuterBox{
  border: 1px solid var(--primary-color13) !important;
}
.transform-capital{
  text-transform: capitalize;
}
.rating span{
  color: var(--primary-color2) !important;
  font-size: 21px !important;
}
.c-rating span{
  color: var(--primary-color2) !important;
  font-size: 23px !important;
}
.flexNowrap{
  flex-wrap: nowrap;
}
.historyTable tbody tr:nth-of-type(odd) > * {
  background-color: #f2f2f2 !important;
}
.historyTable th{
  font-size: 15px !important;
  text-align: center;
  padding: 0.5rem 0.5rem;
  border: 1px solid #dee2e6;
}
.historyTable td{
  font-size: 15px !important;
  padding: 3px 10px !important;
  height: 28px !important;
  border: 1px solid #dee2e6;
}
.historyTable{
  width: 100%;
  font-size: 16px;
  /* overflow-x: scroll; */
}
.printPdfBtn{
  position: absolute !important;
  right: 3%;
  top: 5%;
} 
.order-table-container table td.left-align{
  text-align: left;
}
.order-table-container table th.left-align{
  text-align: left;
}
.subscribeNotification{
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.accordianBlock{
  min-height: 40px;
  margin-top: 15px;
  border: 1px solid var(--primary-color13);
  box-shadow: none !important;
  border-radius: 8px !important;
 
}
.accordianBlock .MuiAccordionSummary-content p{
  font-size: 16px !important;
  font-weight: bold;
  color: var(--primary-color4);
}
.accordianBlock .MuiAccordionSummary-content{
  margin: 0px;
}
.accordianBlock .MuiAccordionSummary-root.Mui-expanded{
  margin: 0px;
  min-height: 40px;
}
.accordianBlock .MuiAccordionSummary-content.Mui-expanded{
  margin: 0px;
  min-height: 40px;
  display: flex;
  align-items: center;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before{
  display: none;
}
.flex-check{
  display: flex;
}
.leaflet-bottom{
  display: none;
}


/* pdf field css */
/* Add styling to the file input container */
.add-category-form__field {
  margin: 10px 0;
}

/* Style the label for the file input */
.add-category-form__field label {
  display: block;
  font-weight: bold;
}

/* Style the file input itself */
.add-category-form__field input[type="file"] {
  display: block;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  transition: border-color 0.3s;
  font-size: 16px;
  line-height: 1.5;
}

/* Style the file input when it's focused */
.add-category-form__field input[type="file"]:focus {
  border-color: #007bff;
  outline: none;
}

/* Style the file input when a file is selected */
.add-category-form__field input[type="file"]:not(:placeholder-shown) {
  border-color: var(--primary-color13);
}
.pdf-container {
  max-width: 100%; 
  /* width: 800px; */
  /* height: 600px !important; */
  /* height: fit-content ; */
  overflow: auto; 
  text-align: center;
}

/* career page css */
.vacancyBox{
  border-top: 2px dotted var(--primary-color2);
  /* border-radius: 10px; */
  margin: 30px 0px;
}
.vacancyTitleBox{
  padding: 20px;
  border: 1px solid var(--primary-color5);
  margin-bottom: 20px;
  border-radius: 10px;
}
.careerBtn{
  display: flex;
  align-items: center;
  justify-content: end;
}
.vacancyDetailBox{
  min-height: 300px;
}
.select-field select{
    font-size: 14px;
    line-height: 1.42857;
    padding: 10px 15px;
    border-radius: 5px;
}

/* community edit dialog width */
.admin-community-dialog .MuiDialog-paper {
	width: 100% !important;
	max-width: 900px !important;
}

/* react calender css */
/* .react-calendar__tile--active {
  background-color: blue;
  color: var(--primary-color5);
  font-weight: bold; 
} */
.selected-dates {
  /* margin-top: 20px; */
  font-weight: bold;
}
.react-calendar__tile--now{
  background: var(--primary-color2)!important;
}

.calenderBox .newSlot {
  background-color: var(--primary-color5) !important;/* Set the background color for selected dates */
  color: var(--primary-color) !important;
  font-weight: bold !important;
  /* border-radius: 50%; */
  width: 35px;
  /* height: 45px; */
}
.calenderBox .hasSlot {
  background-color: transparent;
  color: var(--primary-color5);
  font-weight: bold !important;
  width: auto !important;
}
.calenderBox .selected3{
  background-color: var(--primary-color5) !important;
}
.react-calendar{
  width: 95% !important;
  border: 1px solid var(--primary-color6) !important;
  border-radius: 10px;
  padding: 10px;
}
.react-calendar button{
  font-size: 14px;
}
.react-calendar__navigation button{
  font-size: 30px !important;
  color: var(--primary-color5);
}
.react-calendar__navigation button span{
  font-size: 16px;
  font-weight: bold;
}
.react-calender .react-calendar__month-view__weekdays__weekday abbr[title]{
  color: var(--primary-color4);
  font-weight: 600;
  font-size: 15px !important;
}
.slotBox{
  border: 1px solid var(--primary-color4);
  padding: 3px 15px;
  border-radius: 10px;
}
.slotBox svg{
  cursor: pointer;
}
.slotBox:hover{
  background-color: #db2d6a3b;
  /* color: ; */
}
.react-calendar__tile:disabled{
  cursor: auto !important;
}
.userField{
  margin: 0px;
}
.editAddressBox{
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color18);
  border-top: none;
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 12px;
  padding-left: 10px;
}
.editAddressBox button{
  color: var(--primary-color5);
  font-weight: 600;
  border-bottom: 1px solid var(--primary-color5);
  padding: 0px;
  line-height: 13px;
  border-radius: 0px;
  min-width: auto;
  font-size: 12px;
  cursor: pointer !important;
}
.editAddressBox .defaultBtn{
  border: none;
  cursor: none !important; 
}
.editAddressBox button:hover{
  color: var(--primary-color6);
  background-color: transparent;
  border-bottom: 1px solid var(--primary-color6);
}
.editAddressBox .defaultBtn:hover{
  border: none;
}
.addressArea textarea.form-control {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  resize: none;
}
.disableControl .form-control:disabled{
  background-color: var(--primary-color18) !important;
  opacity: 0.6;
}
.faqContent img{
  width: 100% !important;
}
.edit_parent_box{
  position: relative;
}
.edit_parent_box label{
  padding-bottom: 10px;
}
.edit_icon{
  /* font-size: 20px;
  position: absolute;
  top: 0px;
  left: 60px;
  background-color: rgba(219,45,106,.1);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; */
}
.edit_icon_main{
  position: absolute;
  top: 0px;
  left: 65px;
  font-size: 25px;
  color: var(--primary-color5);
  cursor: pointer;
}
.iconBox img{
  margin-bottom: -37px;
}
.iconBox2 img{
  margin-top: 20px;
}
.menu_active{
  background-color: var(--primary-color2);
}
.menu_active img{
  margin-top: -35px;
  margin-bottom: 0px;
}
.menu_active span{
  color: var(--primary-color4) !important;
}
.sub_menu_active div{
  background-color: var(--primary-color2) !important;
}
.sub_menu_active div span{
  color: var(--primary-color4) !important;
}
.sub_menu_active img{
  margin-top: -31px !important;
  margin-bottom: 0px;
}
.sub_menu_active svg{
  color: var(--primary-color4);
}
.menu_active svg{
  color: var(--primary-color4);
}
.userMenuBox .nested{
  overflow: hidden;
  height: 40px;
}
.nested .iconBox2 img{
  margin-top: 30px;
}
.nested .iconBox2{
  /* overflow: hidden; */
}
.adminMenuBox .menu_active li{
  background-color: var(--primary-color2);
}
.driverMenuBox .menu_active .menulist{
  background-color: var(--primary-color2);
}
.not_found_block{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.not_found_block{
  max-width: 80%;
}
.not_found_main_block{
  width: 100vw;
  height: 100vh;
  background-color: var(--primary-color);
  z-index: 999999;
}
.privacy_block span{
  background-color: transparent !important;
}
.confirm_payment_box{
  text-align: right;
  border-radius: 10px;
  padding: 20px 0px;
}
.user_type_radio_box .form-check{
  font-size: 14px;
}
.recaptcha{
  width: 300px;
  margin-top: 15px;
}
.recaptcha_block{
  width: 300px;
}
.login_Btn:disabled{
  opacity: 0.5;
}
/* .react-calendar__tile--active{
  background: transparent !important;
  color: var(--primary-color1) !important;
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
  background: transparent !important;
  color: var(--primary-color1) !important;
} */
@media screen and (max-width: 768px) {
  .pdf-container {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .pdf-container {
    max-width: 100%;
  }
}

.react-pdf__Page__textContent.textLayer {
  height: auto !important;
}

.react-pdf__Page__annotations.annotationLayer {
  height: auto !important;
}
