
@media (max-width:767px) {
    .footer_column{
        justify-content: start;
        padding-top: 30px;
    }
    .footer_column .css-1hqutne>:not(style){
        margin: 0px;
    }
    .gridRow{
        grid-template-columns: repeat(1,1fr) !important;
    }
    .videoBox{
        width: 80%;
    }
    .font-60{
        font-size: 50px !important;
    }
    .carousel .control-dots{
        left: 42% !important;
    }
    .carousel-Box .carousel.carousel-slider{
        min-height: 650px;
    }
    .videoBox {
        width: 90% !important;
    }
    .flex-grid{
        justify-content: center;
        display: flex;
        align-items: center;
      }
      .purchase_barrel{
        max-width: 100%;
      }
      .country_box{
        grid-template-columns: repeat(2, 1fr);
    }
    .middle_block{
        margin-top: 0px;
    }
    .gridBox{
        grid-template-columns: repeat(1, 1fr) !important;
        text-align: center;
    }
    .center-box{
        justify-content: center;
    }
    .service_inner {
        max-width: 100%;
    }
    .country_carousel {
        max-width: 100%;
        margin: 0 auto;
    }
    .right-header-section{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    .headerBtn {
        padding: 7px 6px !important;
        margin-left: 0px !important;
        font-size: 16px !important;
    }
    .track_icon {
         margin-left: 0px !important; 
        width: 42px;
        height: 42px;
    }
    .about-section-bottom .justify-content-start {
        justify-content: center !important;
    }
    .contact_img {
        max-width: 100%;
        margin-top: 30px;
    }
    .customer-form__address-modify-container {
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .flexBoxDash{
        justify-content: center;
    }
    .community-image-1 {
        max-width: 100%;
        margin: 20px 0px;
    }
    /* .product-Description-box{
        margin-top: 30px;
    } */
    .buyCartTotal {
        min-height: auto;
    }
    .ESt-cost-main{
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 0px 15px;
        background-color: #fbeaf0 !important;
        z-index: 99;
    }
    .ESt-cost{
        margin-top: 0px;
        margin: 10px 0px;
    }
    .estimatorBox{
        margin-bottom: 60px;
    }
    .cartMainContainer{
        margin-top: 30px;
    }
    .tabBox .tabs-block button:first-child{
        margin: 0px;
        padding: 20px 0px;
      }
      .tabs-block button{
        padding: 20px 0px;
        margin: 0px;
      }
      .sendQuantityBox input{
        width: calc(100% - 120px);
        height: 44px;
    }
    .sendQuantityBox button{
        width: 25%;
        min-width: 60px;
        padding: 3px 5px;
    }
    .sendPriceBox{
        display: flex;
        gap: 15px;
        margin-bottom: 12px;
        align-items: center;
        margin-top: 10px;
    }
    .mapBox{
        width: 100% !important;
        height: 400px !important;
    }
    .careerBtn{
        justify-content: start;
        margin-top: 15px !important;
    }
    .recaptcha {
        width: 100%;
      }

}
@media (max-width:575px) {
    .logo_img {
        max-width: 100px;
    }
    .css-r6ewbb-MuiToolbar-root{
        padding: 8px 0px;
    }
    .css-1jguyf8 .MuiTextField-root{
        max-width: 100% !important;
        min-width: 270px !important;
    }
    .footer_column .css-1kabx79-MuiInputBase-root-MuiInput-root{
        min-width: 200px;
    }
    .footer_logo {
        max-width: 80%;
    }
    .listBox li{
        padding-bottom: 0px;
    }
    .formTitle{
        font-size: 30px !important;
    }
    .footer_subscription_title{
        font-size: 25px !important;
    }
    .videoBox{
        width: 90% !important;
    }
    .font-60{
        font-size: 50px !important;
    }
    .font-60-height{
        line-height: 45px !important;
    }
    .video-img::after{
        left: 50%;
        right: auto;
        top: -80px;
        transform: translate(-50%,0%);
    }
    .headerFlexBox{
        display: block;
        text-align: center;
    }
    .headerFlexBox2{
        display: flex !important;
        gap: 10px !important;
    }
    .middleHeader{
        padding: 0px;
    }
    .gridBox{
        grid-template-columns: repeat(1, 1fr);
    }
    .country_box{
        grid-template-columns: repeat(2, 1fr);
    }
    .footer_row1{
        display: block;
        padding: 40px 0px;
    }
    /* .footer_row1 .css-0{
        padding-top: 30px !important;
    } */
    .footer_block2 .css-hlnzui-MuiTypography-root{
        text-align: left;
        padding-top: 10px;
    }
    .driverform__fields {
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .customer-form__address-modify-container {
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .css-ituazv {
        padding-left: 0px !important;
        padding-top: 20px !important;
    }
    .driver-form__photo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .paymentOption.active {
        padding: 5px 5px;
    }
    .videoOverlay{
        display: none;
    }
    .title-button__container{
        flex-direction: column;
    }
    .flexBoxSignup{
        display: block;
    }
    .flexBox2{
        gap: 2px;
    }
    .footer{
        padding-top: 0px;
    }
    .footer_logo{
        padding-bottom: 0px;
    }
    .middle_inner_block{
        width: 100%;
    }
    .errorMsg{
        max-width: 100% !important;
        min-width: 100% !important;
    }


}

@media (max-width:1400px) {
    .footer_row1 .css-0 {
        min-width: 18%;
        gap: 20px;
    }
}
@media (max-width:1300px) {
    .footer_row1 .css-0 {
        min-width: 18%;
        gap: 20px;
    }
}
@media (max-width:1200px) {
    .footer_row1{
        flex-wrap: wrap;
    }
    .footer_row1 .css-0 {
        min-width: 18%;
        gap: 20px;
    }
    .formRow{
        max-width: 100%;
    }
    .gridBox{
        grid-template-columns: repeat(2, 1fr);
    }
    .gridFlexBox{
        justify-content: start;
        margin-top: 30px !important;
    }
    .gridInnerBlockRight{
        margin-left: 0px;
        margin-top: 20px;
    }
    .checkoutCartInfoBox{
        margin-top: 20px;
    }
    .paymentAddressBox{
        max-width: 100%;
    }
    .dashboardInner {
        padding: 10px;
    }
    img {
        max-width: 100%;
    }
    .driver-form{
        flex-direction: column;
    }
    .driver-form__photo {
        text-align: left !important;
    }
    .cartTable.cartTotalTable.MuiBox-root.css-0 {
        margin-top: 20px;
    }
    .optimizeBox {
        right: 10px;
        top: 73px;
    }
    .routeText{
        line-height: 18px !important;
        font-size: 13px !important;
    }
}
@media (max-width: 1250px) {
    /* .headerBtn{
        padding: 7px 20px !important;
    } */
}
@media (max-width: 1400px) {
    .footer_row1{
        gap: 0px !important;
    }
    .driver-form{
        flex-direction: column;
    }
    .driver-form__photo {
        text-align: left !important;
    }
}
@media (max-width:991px) and (min-width: 767px){
    .headerFlexBox2 h4{
        font-size: 20px !important;
    }
    .headerFlexBox2{
        justify-content: start;
    }
    .footer_row1{
        flex-wrap: wrap;
    }
    .footer_row1 .css-0 {
        min-width: 40%;
        gap: 20px;
    }
    .videoBox{
        width: 80% !important;
    }
    .country_box{
        grid-template-columns: repeat(3, 1fr);
    }
    .country_carousel {
        max-width: 100%;
        margin: 0 auto;
    }
    .footer_row1 {
        display: grid;
        gap: 20px !important;
        row-gap: 50px !important;
        grid-template-columns: repeat(2, 1fr);
    }
    .footer_row1 .css-0 {
        min-width: 18%;
        gap: 10px;
    }
}
@media (max-width:900px) {
    .footer_block2 .css-hlnzui-MuiTypography-root{
        text-align: left;
        padding-top: 10px;
    }
    .dashboardContainer{
        width: 100% !important;
    }
    .makeStyles-headerBox-1, .makeStyles-headerBox-2{
        width: 100% !important;
    }
    .sideberOpenIcon{
        display: block !important;
        margin-right: 20px;
    }
    /* .drawer{
        display: none;
    } */
    .closeSidebarIcon{
        display: block !important;
        position: absolute;
        top: 5px;
        right: 5px;
        color: var(--primary-color1);
    }
   .mobileMenu{
    color: var(--primary-color1);
   }
   .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
    top: 65px !important;
   }
   .contact-btn{
    width: 100%;
   }
   /* .mobDropdownDashboard div:nth-child(2){
    top: 16px !important;
   } */
}
@media (max-width:600px) {
    .headerFlexBox2 h4{
        font-size: 20px !important;
    }
    .css-1csc0q5{
        width: 60% !important;
    }
    .sideberOpenIcon {
        position: fixed !important;
        right: 15% !important;
    }
    .font-24{
        font-size: 20px !important;
    }
    .font-25{
        font-size: 22px !important;
    }
    .font-20{
        font-size: 16px !important;
    }
    .font-22{
        font-size: 18px !important;
    }
    .font-30{
        font-size: 25px !important;
    }
    .font-38{
      font-size: 30px !important;
    }
    .font-26{
      font-size: 20px !important;
    }
    .font-45{
      font-size: 40px !important;
    }
    .font-60{
        font-size: 35px !important;
    }
    p, th, td, span{
        font-size: 16px !important;
        line-height: 28px !important;
    }
    .send-detail-table{
        margin-top: 10px;
    }
    .productContainer{
        margin-top: 20px;
    }
    .gridInnerBlockLeft{
        margin-right: 0px;
    }
    .s-container{
        padding: 0px 5px !important;
    }
    .sliderContentBox {
        font-size: 18px !important;
        line-height: 28px !important;
    }
    .homeBtn {
        font-size: 18px !important;
    }
    .cmsfooterBtn{
        font-size: 18px !important;
    }
    .mobileMenu{
        padding: 0px !important;
    }
    .adminInnerPage__tabs-content{
        padding: 20px 10px;
    }
    .table-block table th{
        min-width: 120px;
    }
    .table-block table th:first-child{
        min-width: 100px;
    }
    .driver-tabs{
        padding: 20px 10px !important;
    }
    .mobDropdownDashboard .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
        top: 16px !important;
    }

}
@media (max-width:350px) {
    .css-1jguyf8 .MuiTextField-root{
        max-width: 100% !important;
        min-width: 230px !important;
    }
}

@media (max-width:992px){
    .containerExpand{
        width: 100%;
    }
    .containerExpand2{
        width: 100%;
    }
    .dashContainer, .headerBox{
        width: 100%;
    }
    .sideberOpenIcon {
        position: fixed !important;
        right: 50%;
    }
    .headerBoxExpand{
        width: 100%;
    }
    .gridInnerBlockRight{
        margin-left: 0px;
        margin-top: 20px;
    }
    .addRow{
        margin-top: 15px !important;
    }
    .estimatorBox{
        padding: 10px 0px;
        max-width: 100%;
      }
      .country-select-btn select.form-select {
        width: 100%;
      }
      .homeContainer{
        padding-top: 80px;
      }
      .adminInner{
        padding: 10px;
      }
      .admin-dialog .MuiDialog-paper{
        max-width: 300px !important;
      }
      .supportFormBox {
        width: 250px;
        padding: 0px;
    }

    /* .makeStyles-drawerPaper-7{
        width: 0px;
    } */
    .right-header-section{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
    .headerBtn {
        padding: 7px 5px !important;
        margin-left: 0px !important;
        font-size: 16px !important;
    }
    .track_icon {
         margin-left: 0px !important; 
        width: 42px;
        height: 42px;
    }
    .tabBox {
        padding: 0px 10px;
    }
    .tab-form1 {
        max-width: 100%;
    }
    .tab-form {
        max-width: 100%;
    }
    .react-calendar{
        width: 100% !important;
    }
    .addSlotBox{
        padding-top: 15px !important;
    }
    /* .headerFlexBox2 h4{
        font-size: 20px !important;
    } */
    .headerFlexBox2{
        justify-content: start;
    }
}

@media (max-width:574px) and (min-width: 320px){
    .country_box {
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .country_box {
        gap: 10px;
        row-gap: 10px;
    }
    .carousel-Box .carousel.carousel-slider {
        min-height: 250px !important;
    }
    .carousel .control-dots {
        left: 38% !important;
    }
    .right-header-section{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
    .logo_img {
        max-width: 90px  !important;
    }
    .headerBtn {
        padding: 7px 5px !important;
        margin-left: 0px !important;
        font-size: 16px !important;
        min-width: 64px !important;
    }
    .track_icon {
         margin-left: 0px !important; 
        width: 42px;
        height: 42px;
    }
    .empty-barrels-title{
        margin-bottom: 20px !important;
    }
    .insurance-section-select{
        width: 100% !important;
      }
      span.font-16.color3.pl {
        word-break: break-word;
    }
    .logo_mob_img{
        max-width: 50px !important;
    }
}

@media (max-width:767px) and (min-width: 576px){
    .footer_row1 {
        display: grid;
        gap: 20px !important;
        row-gap: 50px !important;
        grid-template-columns: repeat(2, 1fr);
    }
    .footer_row1 .css-0 {
        min-width: 18%;
        gap: 10px;
    }
}

@media (max-width:1200px) and (min-width: 992px){
    .headerFlexBox2 h4{
        font-size: 25px !important;
    }
    .headerFlexBox2{
        justify-content: start;
    }
    .footer_row1 {
        display: grid;
        gap: 20px !important;
        row-gap: 50px !important;
        grid-template-columns: repeat(3, 1fr);
    }
    .footer_row1 .css-0 {
        min-width: 18%;
        gap: 10px;
    }
    .flexRight{
        justify-content: flex-start !important;
    }
    .right-header-section{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
    .headerBtn {
        padding: 7px 5px !important;
        margin-left: 0px !important;
        font-size: 16px !important;
    }
    .track_icon {
         margin-left: 0px !important; 
        width: 42px;
        height: 42px;
    }
    .manuItemBox {
        justify-content: center;
        gap: 5px;
    }
    .logo_img {
        max-width: 100%;
    }
    
    .logo-image-section{
        width: 90px !important;
    }
}

@media (min-width: 900px){
    .service-main-box{
        max-width: 95% !important;
    }
 
}

@media (max-width:991px) and (min-width: 320px){
    .flexRight{
        justify-content: start !important;
    }
    .estimatorBoxLeft{
        margin-top: 10px !important;
    }
    .estimatorQuantityBox button {
        min-width: 45px;
        max-width: 45px;
        width: auto;
    }
    .barrel-img-box .w-90, .barrel-img-box .w-80, .barrel-img-box .w-60{
        width: 100% !important;
        margin-top: 10px;
    }
    .middle_block{
        padding: 50px 10px !important;
    }
    .gridInnerBlockLeft {
        padding: 15px;
    }
 
}

@media (max-width:480px) and (min-width: 320px){
    .step {
        margin-right: 7px;
    }
    .step-menu span {
        font-size: 10px !important;
        font-weight: bold !important;
    }
    .cartPopup {
        right: -70px !important;
        left: unset !important;
        width: 250px !important;
    }
    .cartInnerSection {
        width: 250px !important;
    }


}
@media (max-width:400px) and (min-width: 320px){
    /* .logo_img {
        max-width: 80px  !important;
    } */
}


@media (max-width:1499px) and (min-width: 1201px){
    .logo_img {
        max-width: 100%;
    }
    .logo-image-section{
        width: 90px !important;
    }
    .right-header-section {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
    .headerBtn{
        margin-left: 0px !important;
        padding: 7px 6px !important;
    }
}
@media (max-width:991px) and (min-width: 900px){
    .logo_img {
        max-width: 100%;
    }
    .logo-image-section{
        width: 90px !important;
    }
    .manuItemBox button {
        font-weight: 600 !important;
        font-size: 16px !important;
    }
    .manuItemBox {
        justify-content: center;
        gap: 5px;
    }
}
@media (max-width:1500px) and (min-width: 320px){
    .cartPopup {
        right: 0px ;
        left: unset ;
    }
}
@media (max-width:900px) and (min-width: 600px){
    .logo_img {
        max-width: 35%;
    }
}
@media (max-width:1300px) and (min-width: 992px){
    .estimatorBox{
        max-width: 95%;
    }
}

@media (max-width:500px) and (min-width: 320px){
.supportFormBox{
    min-width: 250px;
}
}
