.adminInnerPadding {
    padding: 0 !important;
}

/* .adminInner {
    margin-top: 100px;
    padding: 30px !important;
} */

input[type="radio"] {
	border-color: #ffc72c !important;
}

input[type="radio"]:checked {
	background-color: #ffc72c !important;
}

.adminInnerPage__title {
	font-weight: 700 !important;
	font-size: 28px !important;
	line-height: 1 !important;
	letter-spacing: normal !important;
	margin-bottom: 40px !important;
}

.adminInnerPage__tabs {
	border-bottom: 1px solid var(--primary-color3);
}

.adminInnerPage__tabs .MuiTabs-scroller .MuiTabs-flexContainer {
	gap: 1px;
}

.adminInnerPage__tabs .MuiTabs-scroller .MuiTabs-indicator {
	display: none;
}

.adminInnerPage__tabs .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root {
	background-color: var(--primary-color);
	color: var(--primary-color1);
	font-weight: 600;
	font-size: 16px;
	line-height: 1.375;
	padding: 15px;
	min-width: 150px;
	border-radius: 10px 10px 0 0;
}

.adminInnerPage__tabs .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
	background-color: var(--primary-color5);
	color: var(--primary-color);
}

.adminInnerPage__tabs-content {
	background-color: var(--primary-color);
	padding: 20px;
	border-radius: 10px;
}

.orderpage .adminInnerPage__tabs-content {
	border-top-left-radius: 0;
}

.order-search-filter {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.order-search-filter .order-search-filter__search input {
	background-color: #fcfcfc !important;
	border: 1px solid #eaeaea;
	border-radius: 5px;
	padding: 12px 13px;
	font-size: 14px;
	line-height: 1;
	width: 425px;
	max-width: 100%;
}

.order-search-filter .order-search-filter__search input:focus {
	outline: none;
}

.order-search-filter .order-search-filter__filter {
	display: flex;
	align-items: center;
	gap: 8px;
}

.order-search-filter
	.order-search-filter__filter
	.order-search-filter__filter-icon {
	color: #727272;
}

.order-search-filter
	.order-search-filter__filter
	.order-search-filter__filter-dropdown
	select {
	padding: 12px 13px;
	border-radius: 5px;
	border: 0;
	color: #ffffff;
	background: #5c5c5c;
	font-weight: 500;
	font-size: 14px;
	line-height: 1;
}

.order-search-filter
	.order-search-filter__filter
	.order-search-filter__filter-dropdown
	select:focus {
	outline: none;
}

.order-table-container .MuiTableContainer-root,
.pending-action-table-container .MuiTableContainer-root {
	box-shadow: none;
}

.order-table-container table thead {
	background-color: #f6f6f6;
	border-radius: 5px;
}

.order-table-container table th,
.order-table-container table td,
.pending-action-table-container table td {
	text-align: center;
	font-size: 14px !important;
	line-height: 1.3333 !important;
	padding: 14px 20px;
	border-bottom-color: #f6f6f6;
}

.order-table-container table th {
	font-weight: 600;
	border-bottom: 0;
}

.order-table-container table th span,
.order-table-container table td span {
	font-size: 14px !important;
	line-height: 1.3333 !important;
	color: #000000;
}

.order-table-container table th span {
	cursor: pointer;
}

.order-table-container table th span.MuiTableSortLabel-root::after {
	content: "";
	background-image: url("../../public/assets/img/sort-icon.svg");
	display: inline-block;
	width: 8px;
	height: 11px;
	vertical-align: middle;
	margin-left: 6px;
}

.order-table-container
	table
	th
	span.MuiTableSortLabel-root
	svg.MuiSvgIcon-root {
	display: none;
}

.order-table-container table thead tr th:first-child,
.order-table-container table tbody tr td:first-child,
.pending-action-table-container table tbody tr td:first-child {
	text-align: left;
}

.admin-modal {
	display: flex;
	justify-content: center;
	margin: 80px auto;
}

.admin-modal__container {
	background: #fff;
	padding: 30px;
	width: 1080px;
	max-width: 100%;
	border-radius: 15px;
	overflow: auto;
}

.admin-modal__container:focus {
	outline: none;
}

.admin-modal__title-container {
	margin: -30px -30px 30px;
	padding: 12px 30px;
	border-bottom: 1px solid #ebebeb;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.admin-dialog .admin-modal__title-container {
	padding: 12px 30px;
	line-height: 40px !important;
	margin-bottom: 30px;
}

.admin-modal__title-container .admin-modal__title {
	font-weight: 700;
	font-size: 20px;
	line-height: 2;
	letter-spacing: normal;
}

.admin-modal__title-container .btn {
	background-color: var(--primary-color2);
	color: var(--primary-color4);
	border-color: var(--primary-color2);
	min-width: 100px;
}

.admin-modal__title-container .btn:hover {
	background-color: var(--primary-color4);
	color: var(--primary-color);
}

.adminInner .btn,
.admin-modal .btn {
	font-weight: 600;
	font-size: 14px;
	line-height: 1.42857;
	padding: 10px 14px;
	border-radius: 6px;
	min-width: 100px;
}

.adminInner .btn.btn-primary,
.admin-modal .btn.btn-primary {
	background-color: var(--primary-color6);
	color: var(--primary-color);
	border-color: var(--primary-color6);
}

.adminInner .btn.btn-secondary,
.admin-modal .btn.btn-secondary {
	background-color: var(--primary-color5);
	color: var(--primary-color);
	border-color: var(--primary-color5);
}

.adminInner .btn.btn-primary:hover,
.adminInner .btn.btn-secondary:hover,
.admin-modal .btn.btn-primary:hover,
.admin-modal .btn.btn-secondary:hover {
	background-color: var(--primary-color2) !important;
	color: var(--primary-color4) !important;
	border-color: var(--primary-color2);
}

.admin-modal .section-title {
	font-weight: 700;
	font-size: 16px;
	line-height: 1.375;
	margin-bottom: 18px;
	display: flex;
	align-items: center;
}

.admin-modal .section-title .section-title__link {
	margin-left: auto;
	font-weight: 600;
	font-size: 16px !important;
	line-height: 1.375 !important;
}

.admin-modal .section-title .section-title__link a {
	color: var(--primary-color5);
	text-decoration: underline !important;
}

.admin-modal .section-title.section-title-btn {
	display: flex;
	align-items: center;
	gap: 20px;
}

.admin-modal .section-title.space-top {
	margin-top: 30px;
}

.admin-modal .edit-order-form__field--top {
	display: flex;
	/* align-items: flex-start; */
	justify-content: flex-end;
	gap: 20px;
}

.admin-modal .edit-order-form__field--bottom {
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 25px;
	grid-row-gap: 20px;
}

.admin-modal .edit-order-form .edit-order-form__fields {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.admin-modal .form-label,
.adminInner .form-label,
.adminInner .form-check-label {
	color: #000000;
	font-weight: 600;
	font-size: 14px;
	line-height: 1.42857;
	margin-bottom: 5px;
	display: block;
}

.adminInner .form-check-label {
	margin-bottom: 0;
}

.admin-modal .form-control,
.adminInner .form-control {
	font-size: 14px;
	line-height: 1.42857;
	padding: 10px 15px;
	border-radius: 5px;
}

.admin-modal textarea.form-control,
.adminInner textarea.form-control {
	min-height: 110px;
}

.edit-order-table-container .MuiTableContainer-root {
	box-shadow: none;
}

.edit-order-table-container .sortable-table {
	border: 0;
}

.edit-order-table-container .sortable-table span {
	font-weight: 600;
}

.edit-order-table-container .sortable-table thead {
	background-color: #dbebe3;
	color: var(--primary-color6);
	font-weight: 600;
}

.edit-order-table-container .sortable-table thead th,
.edit-order-table-container .sortable-table td {
	padding: 10px 15px;
}

.edit-order-table-container .sortable-table span,
.edit-order-table-container .sortable-table td {
	font-size: 14px !important;
	line-height: 1.42857 !important;
}

.edit-order-table-container .sortable-table thead th span {
	display: block;
}

.admin-modal__container .box-details {
	display: flex;
	justify-content: space-between;
	gap: 40px;
}

.admin-modal__container .box-details__left {
	width: 60%;
	/* max-width: 515px; */
	flex-grow: 1;
}

.admin-modal__container .box-details__right {
	width: 40%;
	max-width: 360px;
}

.order-summary-form {
	display: block;
	margin-top: 32px;
}

.order-summary-form__fields {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 25px;
	grid-row-gap: 20px;
}

.admin-modal__container .order-summary {
	background-color: #fafafa;
	border: 1px solid #ebebeb;
	border-radius: 10px;
	padding: 25px;
}

.admin-modal__container .order-summary__title {
	font-weight: 700;
	font-size: 20px !important;
	line-height: 1.3;
	border-bottom: 1px solid #d5d5d5;
	padding-bottom: 15px;
}

.admin-modal__container .order-summary__row {
	display: flex;
	justify-content: space-between;
	padding: 20px 0;
	color: var(--primary-color6);
	font-weight: 600;
	font-size: 16px;
	line-height: 1.375;
	letter-spacing: 0.03em;
	border-bottom: 1px solid #ebebeb;
}

.admin-modal__container .order-summary__row--footer {
	border-bottom: 0;
	padding-bottom: 0;
	border-top: 1px solid #d5d5d5;
	color: var(--primary-color6);
	font-weight: 700;
	font-size: 19px;
	line-height: 1.36842;
}

.shipping-order-modal {
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 30px;
}

.shipping-order-form__modal {
	flex-grow: 1;
}

.your-order-total-box {
	width: 40%;
	max-width: 360px;
	/* border: 1px solid #e2e2e2; */
	border-radius: 10px;
	padding: 0 25px 25px;
	background-color: #fafafa;
}

.your-order-total-box .section-title {
	margin: 0;
	padding: 14px 0;
	text-align: center;
	font-size: 20px;
	line-height: 1.3;
	border-bottom: 1px solid #d5d5d5;
}

.your-order-total-table__container {
	box-shadow: none !important;
	background-color: transparent !important;
}

.your-order-total-table thead th {
	font-weight: 700 !important;
	font-size: 18px !important;
	line-height: 1.3333 !important;
}

.your-order-total-table tbody th,
.your-order-total-table tbody td {
	font-size: 14px !important;
	line-height: 1.357 !important;
}

.your-order-total-table tbody tr:last-child td {
	font-weight: 700 !important;
	font-size: 18px !important;
	line-height: 1.3333 !important;
	color: var(--primary-color6);
	padding-bottom: 0;
}

.your-order-total-table tbody tr:last-child td {
	border-bottom: 0;
}

.shipping-order-form__fields {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 25px;
	grid-row-gap: 20px;
}

.customers-modal .admin-modal__container {
	width: 686px;
}

.admin-modal .edit-order-form .customer-form__fields {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 25px;
	grid-row-gap: 20px;
	margin-bottom: 30px;
}

.admin-modal
	.edit-order-form
	.customer-form__fields
	.edit-order-form__field
	.form-label {
	color: #000000;
}

.customer-form__address-modify-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 25px;
	grid-row-gap: 15px;
	margin-bottom: 30px;
}

.customer-form__address-modify {
	position: relative;
}

.customer-form__address-modify-edit,
.customer-form__address-modify-delete {
	position: absolute !important;
	right: 5px;
	z-index: 1;
	/* border: 0; */
	background: transparent;
	padding: 0;
	line-height: 1;
}

.customer-form__address-modify-edit {
	top: 5px;
	color: #939393 !important;
}

.customer-form__address-modify-delete {
	top: 36px;
	color: #db2d6a !important;
}

.admin-modal .customer-form .edit-order-form__field {
	width: 300px;
}

.admin-modal .customer-form .edit-order-form__field--addresses {
	width: 100%;
}

.admin-modal .customer-form .edit-order-form__field--addresses .form-label {
	font-weight: 700;
	font-size: 16px;
	line-height: 1.375;
	margin-bottom: 10px;
}

.admin-modal
	.edit-order-form
	.edit-order-form__field
	.customer-form__address-modify
	.form-control {
	padding-right: 50px;
}

.admin-modal__order-history {
	margin-bottom: 30px;
}

.admin-modal__order-history .section-title {
	margin-bottom: 14px;
}

.admin-modal__order-history
	.order-table-container
	table
	th
	span.MuiTableSortLabel-root::after {
	display: none;
}

.admin-modal__pending-action {
	margin-bottom: 30px;
}

.pending-action-table-container .MuiTableContainer-root {
	border: 1px solid #ebebeb;
}

.pending-action-table-container table td {
	padding: 0 15px;
}

.driver-table__name {
	cursor: pointer;
}

.driver-table-container table thead tr th:first-child,
.driver-table-container table tbody tr td:first-child {
	text-align: center;
}

.driver-table-container table tbody tr td .center-avatar {
	margin: 0 auto;
}

.driver-form__container {
	max-width: 1080px;
	padding: 30px;
	background-color: var(--primary-color);
	border-radius: 15px;
}

.driver-form {
	display: flex;
	justify-content: space-between;
	gap: 40px;
	margin-bottom: 30px;
}

.driverform__fields {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 25px;
	grid-row-gap: 20px;
	width: 625px;
	max-width: 100%;
}

.driverform__field .form-label {
	color: #000000;
	font-weight: 600;
	font-size: 14px;
	line-height: 1.42857;
	margin-bottom: 5px;
	display: block;
	text-align: left;
}

.driver-form__photo {
	text-align: center;
}

.driver-form__photo .btn {
	margin: 15px auto;
	background-color: var(--primary-color6);
	color: var(--primary-color);
	font-weight: 600;
	font-size: 14px;
	border-color: var(--primary-color6);
	padding: 10px 12px;
	border-radius: 6px;
}

.driver-form__photo .btn:hover {
	background-color: #ffc72c;
}

.driver-pending-orders {
	margin-bottom: 30px;
}

.admin-dialog .MuiDialog-paper {
	width: 100% !important;
	max-width: 744px ;
}

.admin-dialog--lg .MuiDialog-paper {
	max-width: 1144px !important;
}

.driver-dialog .MuiDialog-paper {
	max-width: 1144px !important;
}

.customer-table__action--edit,
.customer-table__action--delete {
	text-decoration: underline;
	cursor: pointer;
}

.customer-table__action--edit {
	color: var(--primary-color6) !important;
}

.customer-table__action--delete {
	color: #db2d6a !important;
}

.driverProfilePage .driver-form {
	margin-bottom: 0;
}

.driver-dialog-form__fields {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 25px;
	grid-row-gap: 20px;
}

.driver-dialog-form__field {
	width: 192px;
}

.driver-dialog__order-details-table {
	margin-top: 30px;
	width: 100%;
	max-width: 640px;
}

.driver-dialog__order-details-table tr td:last-child {
	color: #db2d6a;
}

.driver-dialog__order-details-table tr:last-child td:first-child {
	text-align: right;
	font-size: 18px !important;
}

.driver-dialog__order-details-table tr:last-child td:last-child {
	font-weight: 700;
	font-size: 18px !important;
}

.title-button__container {
	margin-bottom: 40px !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

.title-button__container .adminInnerPage__title {
	margin-bottom: 0 !important;
}

.title-button__container .btn {
	font-weight: 600;
	font-size: 16px;
	line-height: 1.375;
	padding: 14px 24px;
}

.title-button__container .btn-primary {
	background-color: #db2d6a;
	color: #ffffff;
}

.title-button__container .btn-primary:hover {
	background-color: #ffc72c;
	color: var(--primary-color6);
}

.shippingPricePage__table-container {
	margin-bottom: 30px;
	background-color: var(--primary-color);
	padding: 20px;
	border-radius: 10px;
}

.shippingPricePage__table-container .section-title {
	font-weight: 700;
	font-size: 16px;
	line-height: 1.375;
	margin-bottom: 15px;
}

.shipping-price-form__fields {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 25px;
	grid-row-gap: 20px;
}

.shipping-price-form__field {
	width: 300px;
}

.btns-add-shipping-price {
	justify-content: center !important;
	padding: 30px !important;
}

.islander-address-form-container {
	max-width: 680px;
	background-color: var(--primary-color);
	border-radius: 10px;
	padding: 30px;
}

.islander-address-form-button {
	text-align: right;
	margin-bottom: 25px;
}

.islander-address-form__fields {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 25px;
	grid-row-gap: 20px;
}

.islander-address-form__field--full {
	grid-column: 1 / span 2;
}

.productListPage__container {
	background-color: var(--primary-color);
	border-radius: 10px;
	padding: 20px;
}

.product-search-filter {
	justify-content: flex-start;
	gap: 8px;
}

.product-table-container table td .product-table-container__stock,
.product-categories-table-container table td .product-categories-table__stock {
	background-color: #eff5f2;
	border-radius: 5px;
	color: #033d1e;
	padding: 8px;
	font-weight: 600;
	font-size: 12px !important;
	line-height: 1.5 !important;
	min-width: 160px;
	display: inline-block;
	vertical-align: middle;
}

.product-table-container
	table
	td.order-table-container__outofstock
	.product-table-container__stock,
.product-categories-table-container
	table
	td.order-table-container__outofstock
	.product-categories-table__stock {
	background-color: #ffecf2;
	color: #db2d6a;
}

.product-categories-table .textLeft {
	text-align: left;
}

.add-product-form__row {
	row-gap: 20px;
}

.add-product-form__visibility {
	display: flex;
	align-items: center;
	column-gap: 90px;
	margin-top: 13px;
}

.add-category-form .add-product-form__visibility {
	column-gap: 70px;
}

.add-product-form__visibility .form-check-label {
	font-size: 14px;
}

.add-product-form__categories {
	display: flex;
	align-items: center;
	gap: 20px;
}

.add-product-form__categories .MuiTextField-root {
	margin: 0;
}

.add-product-form__categories .MuiTextField-root .MuiInputLabel-formControl {
	font-size: 14px;
	line-height: 1;
}

.add-product-form__categories .MuiChip-root {
	background-color: #dbebe3;
	color: #033d1e;
	border-radius: 5px;
}

.add-product-form__categories .MuiChip-root .MuiChip-label {
	font-size: 14px !important;
	font-weight: 600;
}

.add-product-form__categories .MuiChip-deleteIcon {
	color: #db2d6a !important;
}

.add-product-form__row .section-title {
	margin-top: 20px;
	color: #033d1e;
	font-weight: 700;
	font-size: 20px;
	line-height: 1.3;
}

.add-category-form__field {
	width: 300px !important;
	max-width: 100% !important;
}

.paymentPage__mode {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 30px;
}

.paymentPage__mode .form-label {
	margin-bottom: 0;
	font-weight: 700;
	font-size: 16px;
	color: #727272;
	margin-right: 20px;
}

.paymentPage__keys {
	background-color: var(--primary-color);
	border-radius: 10px;
	padding: 30px;
}

.paymentPage__keys + .paymentPage__keys {
	margin-top: 30px;
}

.paymentPage__keys .section-title {
	font-weight: 700;
	font-size: 16px;
	line-height: 1.375;
	margin-bottom: 20px;
}

.paymentKeysForm__field {
	display: flex;
	/* align-items: center; */
	margin-bottom: 10px;
	gap: 30px;
}

.paymentKeysForm__field:last-child {
	margin-bottom: 0;
}

.paymentKeysForm .form-label {
	white-space: nowrap;
	margin-top: 11px;
	margin-bottom: 0;
	text-align: right;
	color: #033d1e;
	min-width: 200px;
}

.paymentKeysForm .form-control {
	width: 100%;
	max-width: 360px;
}
.generalSettingForm .form-control {
	max-width: 600px !important;
}

.pickupLocationsPage {
	max-width: 685px;
}

.order-table-container table td.pickup-locations-table__hide span {
	color: #db2d6a;
}
.review_container{
	max-width: 800px;
	max-height: 500px;
}
